import { useCallback, useEffect, useState } from "react";

export function useLocalstorageState<T>(
  key: string,
  initialValue: T,
  noLocal?: boolean
): [T, (value: T) => void, boolean] {
  const [state, setInternalState] = useState<T>(initialValue);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (noLocal) {
      setIsLoaded(true);
      return;
    }
    const value = localStorage.getItem(key);
    if (value) {
      setInternalState(JSON.parse(value));
    }

    setIsLoaded(true);
  }, [key, noLocal]);

  const setState = useCallback(
    (value: T) => {
      if (!noLocal) {
        localStorage.setItem(key, JSON.stringify(value));
      }

      setInternalState(value);
    },
    [noLocal, key]
  );

  return [state, setState, isLoaded];
}
