import { ApiMetric } from "@incendium/api";
import { MetricExplorer, SelectedMetricProvider } from "features/analytics";

function MetricExplorerPage() {
  return (
    <>
      <SelectedMetricProvider initialMetric={ApiMetric.METRIC_PAGE_VIEWS_COUNT}>
        <MetricExplorer />
      </SelectedMetricProvider>
    </>
  );
}

export default MetricExplorerPage;
