import { useSelectedProject } from "Hooks/useSelectedProject";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";

function useMetricExplorerNavigation() {
  const history = useHistory();
  const { selectedClient, selectedProject } = useSelectedProject();

  const onMetricClick = useCallback(
    (metric: string) => {
      if (!selectedClient || !selectedProject) {
        return;
      }
      history.push({
        pathname: `/clients/${selectedClient.id}/projects/${selectedProject.id}/analyse/optimization/metric-explorer`,
        search: `m=${metric}`,
      });
    },
    [history, selectedClient, selectedProject]
  );

  return onMetricClick;
}

export default useMetricExplorerNavigation;
