import DataTable from "Components/UI/DataTable";
import { styled, Box, Divider } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { IChartData, TChartData } from "Interfaces";
import {
  isDimension,
  isJourneyDimension,
  isTrendDimension,
} from "features/analytics/utils/utils";
import {
  AnalyticsCell,
  AnalyticsUserJourneyColumns,
  dimensionToName,
  formatDimension,
  ICompare,
  IOnClickMap,
  metricToName,
  useFormatMetric,
} from "features/analytics";
import { ApiDimension, ApiMetric } from "@incendium/api";
import { percentageChange } from "Helpers/percentage";
import moment from "moment";
import useMetricExplorerNavigation from "features/analytics/hooks/useMetricExplorerNavigation";

const StyledWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: -14,
  right: -14,
  bottom: -47,
  top: 10,
}));

interface IAnalyticsDataTableProps {
  data: TChartData[];
  totals?: TChartData;
  onClick?: { [field: string]: (v: string, o?: string) => void };
  pageSize?: number;
  comparison?: boolean;
  comparisonChartData?: IChartData;
  colWidths?: { [d: string]: number };
  pinAt?: number;
  disableMetricClick?: boolean;
}

function AnalyticsDataTable({
  data,
  totals,
  onClick,
  comparison,
  comparisonChartData,
  pageSize,
  colWidths,
  pinAt,
  disableMetricClick,
}: IAnalyticsDataTableProps) {
  const [columns, setColumns] = useState<(ColDef | ColGroupDef)[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [totalRow, setTotalRow] = useState<any[] | undefined>(undefined);
  const formatMetric = useFormatMetric();
  const onMetricClick = useMetricExplorerNavigation();

  const cellRenderer = useCallback(
    (
      key,
      { valueFormatted, value, colDef, data, ...f },
      colIsDimension,
      comparison,
      comparisonChartData
    ) => {
      let compare: ICompare = {};
      if (comparison && comparisonChartData) {
        let found = comparisonChartData.data?.find((d) => d.name === data.name);

        if (!colIsDimension && found && typeof value === "number") {
          let change = percentageChange(
            Number(found[colDef.field]) || 0,
            value || 0
          );
          compare = {
            direction: change > 0 ? "up" : change < 0 ? "down" : "same",
            value: Number(change) || 0,
            formatedValue: formatMetric(
              key as ApiMetric,
              Number(found[colDef.field]) || 0
            ),
          };
        }
      }

      const fn: IOnClickMap[] | undefined =
        onClick && onClick[key]
          ? [
              {
                text: "Click To Explore",
                fn: () => onClick[key](value),
              },
            ]
          : [];
      if (!colIsDimension && !disableMetricClick) {
        fn.push({
          text: "View in Metric Explorer",
          fn: () => onMetricClick(key),
        });
      }

      return colIsDimension && isJourneyDimension(key as ApiDimension) ? (
        <AnalyticsUserJourneyColumns
          formatedValue={valueFormatted}
          onClick={onClick ? () => onClick[key](value) : undefined}
        />
      ) : (
        <AnalyticsCell
          dimensionName={data.name}
          valueFormatted={valueFormatted}
          onClick={fn}
          compare={compare}
          headerName={colDef.headerName}
          isDimension={colIsDimension}
        />
      );
    },
    [onClick, formatMetric, onMetricClick, disableMetricClick]
  );

  useEffect(() => {
    if (!data || !data[0]) {
      return;
    }
    const cols: (ColDef | ColGroupDef)[] = Object.keys(data[0])
      .filter((k) => k !== "name")
      .map((d, i) => {
        const colIsDimension = isDimension(d);
        let headerName = colIsDimension
          ? dimensionToName(d as ApiDimension)
          : metricToName(d as ApiMetric);

        const c: ColDef = {
          headerName,
          field: d,
          pinned: pinAt
            ? i < pinAt
              ? "left"
              : undefined
            : colIsDimension
            ? "left"
            : undefined,
          minWidth:
            colWidths && colWidths[`${d}`]
              ? colWidths[`${d}`]
              : colIsDimension
              ? isJourneyDimension(d as ApiDimension)
                ? 500
                : 200
              : 130,
          width: colWidths && colWidths[`${d}`] ? colWidths[`${d}`] : undefined,
          valueFormatter: ({ value }) => {
            return colIsDimension
              ? formatDimension(d as ApiDimension, value)
              : value === "..."
              ? "..."
              : formatMetric(d as ApiMetric, value);
          },
          cellRenderer: (params) =>
            cellRenderer(
              d,
              params,
              colIsDimension,
              comparison,
              comparisonChartData
            ),
        };

        if (isTrendDimension(d as ApiDimension)) {
          c.comparator = (date1: string, date2: string) => {
            return moment(date1).diff(moment(date2));
          };
        }

        return c;
      });

    const rows = (data || []).map((v, i) => {
      return {
        id: i,
        ...v,
      };
    });

    setColumns(cols);
    setRows(rows);
    if (totals) {
      setTotalRow([totals]);
    }
  }, [
    data,
    totals,
    comparison,
    comparisonChartData,
    cellRenderer,
    colWidths,
    pinAt,
    formatMetric,
  ]);

  return (
    <StyledWrapper>
      <Divider />
      <DataTable
        colDefs={columns}
        rows={rows}
        totalRow={totalRow}
        pageSize={pageSize}
      />
    </StyledWrapper>
  );
}

export default AnalyticsDataTable;
