import { ApiMetric } from "@incendium/api";
import useAppendSearchState from "Hooks/useAppendSearchState";
import { CallbackOrVal } from "Interfaces";
import { createContext, ReactNode, useContext, useMemo } from "react";

interface SelectedMetricContextType {
  selectedMetric: ApiMetric;
  setSelectedMetric: (v: CallbackOrVal<ApiMetric>) => void;
}

const SelectedMetricContext = createContext<SelectedMetricContextType>({
  selectedMetric: ApiMetric.METRIC_NOT_SET,
  setSelectedMetric: () => {},
});

export const useSelectedMetric = () => useContext(SelectedMetricContext);

const SelectedMetricProvider = ({
  children,
  initialMetric,
}: {
  children: ReactNode;
  initialMetric: ApiMetric;
}) => {
  const [selectedMetric, setSelectedMetric] = useAppendSearchState<ApiMetric>(
    "m",
    initialMetric
  );
  const contextValue = useMemo(
    () => ({ selectedMetric, setSelectedMetric }),
    [selectedMetric, setSelectedMetric]
  );
  return (
    <SelectedMetricContext.Provider value={contextValue}>
      {children}
    </SelectedMetricContext.Provider>
  );
};

export default SelectedMetricProvider;
