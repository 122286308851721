import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
  ApiYAxisChartType,
} from "@incendium/api";
import { Autocomplete, Grid, Portal, Stack, TextField } from "@mui/material";
import AccordianGlassCard from "Components/UI/AccordianGlassCard";
import { AnalyticsSpacing, PageSubAction } from "consts";
import {
  AnalyticsCard,
  InsightsCard,
  metricToName,
  InsightsBlock,
  useSelectedMetric,
} from "features/analytics";
import { SelectedDimensionProvider } from "features/analytics";
import { enumToArray } from "Helpers/enumToText";

function MetricExplorer() {
  const { selectedMetric, setSelectedMetric } = useSelectedMetric();

  const options = enumToArray(ApiMetric).filter(
    (m) => m !== ApiMetric.METRIC_NOT_SET
  );
  return (
    <>
      <Portal container={() => document.getElementById(PageSubAction)}>
        <Autocomplete
          sx={{ width: 250 }}
          options={options}
          value={selectedMetric}
          getOptionLabel={(o) => metricToName(o)}
          onChange={(e, v) => {
            setSelectedMetric(v || ApiMetric.METRIC_AVERAGE_SALE_VALUE);
          }}
          renderInput={(params) => (
            <TextField {...params} label={"Select Metric To Explore"} />
          )}
        />
      </Portal>

      <Stack spacing={2}>
        <AccordianGlassCard
          title="Overview"
          localStorageKey="metric-explorer-overview"
        >
          <Grid container spacing={AnalyticsSpacing} mb={4}>
            <Grid item xs={8} sx={{ minHeight: 400 }}>
              <AnalyticsCard
                chart={{
                  name: "Over Time",
                  dimension: [ApiDimension.DIMENSION_SESSION_START_BY_DAY],
                  template: ApiChartTemplate.TREND,
                  type: ApiChartType.GRAPH,
                  attributes: [],
                  comparison: true,
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [selectedMetric],
                    },
                  ],
                  displayOptions: {
                    noAttributionToggle: true,
                    disabledMetricClick: true,
                  },
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <AnalyticsCard
                chart={{
                  name: "By Week",
                  dimension: [ApiDimension.DIMENSION_SESSION_START_BY_WEEK],
                  template: ApiChartTemplate.TREND,
                  type: ApiChartType.GRAPH,
                  attributes: [],
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [selectedMetric],
                      chart: {
                        [selectedMetric]: ApiYAxisChartType.BAR,
                      },
                    },
                  ],
                  displayOptions: {
                    noAttributionToggle: true,
                    disabledMetricClick: true,
                  },
                }}
              />
            </Grid>
            <Grid item xs={4} sx={{ minHeight: 400 }}>
              <AnalyticsCard
                chart={{
                  name: "New vs Returning",
                  dimension: [ApiDimension.DIMENSION_IS_RETURNING_CUSTOMER],
                  template: ApiChartTemplate.TREND,
                  type: ApiChartType.GRAPH,
                  orderBy: `${ApiDimension.DIMENSION_IS_RETURNING_CUSTOMER}`,
                  attributes: [],
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [selectedMetric],
                      chart: {
                        [selectedMetric]: ApiYAxisChartType.BAR,
                      },
                    },
                  ],
                  displayOptions: {
                    noAttributionToggle: true,
                    disabledMetricClick: true,
                  },
                }}
              />
            </Grid>
            <Grid item xs={8} sx={{ minHeight: 400 }}>
              <AnalyticsCard
                chart={{
                  name: "By Channel",
                  dimension: [ApiDimension.DIMENSION_CHANNEL],
                  template: ApiChartTemplate.SNAPSHOT,
                  type: ApiChartType.GRAPH,
                  attributes: [],
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [selectedMetric],
                      chart: {
                        [selectedMetric]: ApiYAxisChartType.BAR,
                      },
                    },
                  ],
                  displayOptions: {
                    noAttributionToggle: true,
                    disabledMetricClick: true,
                  },
                }}
              />
            </Grid>
          </Grid>
        </AccordianGlassCard>
        <SelectedDimensionProvider
          initialDimension={ApiDimension.DIMENSION_CHANNEL}
        >
          <InsightsCard
            title="Traffic Source Insights"
            dimensions={[
              ApiDimension.DIMENSION_CHANNEL,
              ApiDimension.DIMENSION_SOURCE,
              ApiDimension.DIMENSION_MEDIUM,
              ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN,
              ApiDimension.DIMENSION_CONTENT,
              ApiDimension.DIMENSION_TERM,
            ]}
            body={<InsightsBlock />}
          />
        </SelectedDimensionProvider>

        <SelectedDimensionProvider
          initialDimension={ApiDimension.DIMENSION_LANDING_PAGE_LOCATION}
        >
          <InsightsCard
            title="Content Insights"
            dimensions={[
              ApiDimension.DIMENSION_LANDING_PAGE_LOCATION,
              ApiDimension.DIMENSION_LOCATION,
              ApiDimension.DIMENSION_LANDING_PAGE_PATH,
              ApiDimension.DIMENSION_PATH,
            ]}
            body={<InsightsBlock />}
          />
        </SelectedDimensionProvider>

        <SelectedDimensionProvider
          initialDimension={ApiDimension.DIMENSION_DEVICE}
        >
          <InsightsCard
            title="Technology Insights"
            dimensions={[
              ApiDimension.DIMENSION_DEVICE,
              ApiDimension.DIMENSION_BROWSER,
              ApiDimension.DIMENSION_BROWSER_WITH_VERSION,
              ApiDimension.DIMENSION_OS,
            ]}
            body={<InsightsBlock />}
          />
        </SelectedDimensionProvider>
        <SelectedDimensionProvider
          initialDimension={ApiDimension.DIMENSION_CITY}
        >
          <InsightsCard
            title="Geographic Insights"
            dimensions={[
              ApiDimension.DIMENSION_CITY,
              ApiDimension.DIMENSION_REGION,
              ApiDimension.DIMENSION_COUNTRY,
            ]}
            body={<InsightsBlock />}
          />
        </SelectedDimensionProvider>
      </Stack>
    </>
  );
}

export default MetricExplorer;
