import { ApiSimpleSubscription } from "@incendium/api";
import {
  Autocomplete,
  Button,
  DrawerProps,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SavingButton from "Components/UI/SavingButton";
import StyledDrawer, {
  StyledDrawerActions,
  StyledDrawerContainer,
  StyledDrawerTitle,
} from "Components/UI/StyledDrawer";
import useContracts from "features/subscription/hooks/useContracts";
import useSubscriptionProducts from "features/subscription/hooks/useSubscriptionProducts";
import produce from "immer";
import { CallbackOrVal } from "Interfaces";
import { useEffect, useMemo, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { usePrevious, useUpdateEffect } from "react-use";

interface ISubscriptionDrawerProps extends DrawerProps {
  onSubmit: () => void;
  subscription: ApiSimpleSubscription;
  setSubscription: (v: CallbackOrVal<ApiSimpleSubscription>) => void;
  saving: boolean;
}
function SubscriptionDrawer({
  open,
  onClose,
  onSubmit,
  subscription,
  setSubscription,
  saving,
}: ISubscriptionDrawerProps) {
  const { contracts, loading } = useContracts();
  const { subscriptionProducts, loading: pLoading } = useSubscriptionProducts();
  const [price, setPrice] = useState((subscription.price || 0) / 100);
  const prevOpen = usePrevious(open);

  useUpdateEffect(() => {
    setSubscription((sub) =>
      produce(sub, (draft) => {
        draft.price = Math.round(price * 100);
      })
    );
  }, [price]);

  useEffect(() => {
    if (!open && prevOpen) {
      setSubscription({});
      setPrice(0);
    }
    if (!prevOpen && open) {
      setPrice((subscription.price || 0) / 100);
    }
  }, [open, prevOpen, subscription, setSubscription]);

  const selectedProduct = useMemo(() => {
    return (subscriptionProducts || []).find(
      (f) => f.id === subscription.productId
    );
  }, [subscription.productId, subscriptionProducts]);

  const selectedContract = useMemo(() => {
    return (contracts || []).find((f) => f.id === subscription.contractId);
  }, [subscription.contractId, contracts]);

  return (
    <StyledDrawer anchor="right" open={open} onClose={onClose}>
      <StyledDrawerTitle>
        <Typography variant="subtitle1">Subscription</Typography>
      </StyledDrawerTitle>
      <ValidatorForm onSubmit={onSubmit}>
        <StyledDrawerContainer sx={{ width: 600 }}>
          <Stack spacing={2}>
            <TextValidator
              label="Subscription Name"
              value={subscription.name || ""}
              variant="outlined"
              name="name"
              size="small"
              fullWidth
              validators={["required"]}
              errorMessages={["Name is required"]}
              onChange={(e: any) =>
                setSubscription(
                  produce(subscription, (draft) => {
                    draft.name = e.target.value;
                  })
                )
              }
            />
            <TextValidator
              label="Subscription Description"
              value={subscription.description || ""}
              variant="outlined"
              name="description"
              size="small"
              fullWidth
              multiline
              rows={4}
              rowsMax={8}
              validators={["required"]}
              errorMessages={["Description is required"]}
              onChange={(e: any) =>
                setSubscription(
                  produce(subscription, (draft) => {
                    draft.description = e.target.value;
                  })
                )
              }
            />
            <TextValidator
              label="Subscription Monthly Price"
              value={price}
              type="number"
              variant="outlined"
              name="price"
              size="small"
              fullWidth
              validators={["required"]}
              errorMessages={["Price is required"]}
              onChange={(e: any) => setPrice(e.target.value)}
            />
            <Autocomplete
              id="product-picker"
              size="small"
              onChange={(e, value) => {
                setSubscription((sub) =>
                  produce(sub, (draft) => {
                    draft.productId = value?.id;
                  })
                );
              }}
              disableClearable
              value={selectedProduct}
              isOptionEqualToValue={(option, value) => {
                return option?.id === value?.id;
              }}
              options={subscriptionProducts}
              loading={pLoading}
              getOptionLabel={(o) => o.name || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select a product"
                  variant="outlined"
                />
              )}
            />
            <Autocomplete
              id="contract-picker"
              size="small"
              onChange={(e, value) => {
                setSubscription((sub) =>
                  produce(sub, (draft) => {
                    draft.contractId = value?.id;
                  })
                );
              }}
              value={selectedContract}
              isOptionEqualToValue={(option, value) => {
                return option?.id === value?.id;
              }}
              options={contracts}
              loading={loading}
              getOptionLabel={(o) => o.name || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select a contract"
                  variant="outlined"
                />
              )}
            />
          </Stack>
        </StyledDrawerContainer>
        <StyledDrawerActions>
          <Button
            onClick={(e) => onClose!(e, "backdropClick")}
            color="secondary"
          >
            Cancel
          </Button>
          <SavingButton
            variant="contained"
            color="primary"
            type="submit"
            saving={saving}
          >
            Save
          </SavingButton>
        </StyledDrawerActions>
      </ValidatorForm>
    </StyledDrawer>
  );
}

export default SubscriptionDrawer;
