import {
  ApiProjectSubscriptionStatus,
  ApiSimpleProjectSubscription,
} from "@incendium/api";
import { Alert, Box, Stack, Typography } from "@mui/material";
import { CenterPage } from "Components/CenterPage/CenterPage";
import Loading from "Components/Loading/Loading";
import { getProjectSubscrition, SubscriptionCard } from "features/subscription";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

function ThankyouPage() {
  const { search } = useLocation();
  const { projectId } = useParams<{ projectId: string }>();
  const [subscription, setSubscription] =
    useState<ApiSimpleProjectSubscription | null>(null);
  const [notPaid, setNotPaid] = useState(false);

  const loadSubcription = useCallback(async (projectId: number) => {
    const res = await getProjectSubscrition(projectId);
    setSubscription(res);
  }, []);

  useEffect(() => {
    loadSubcription(Number(projectId));
  }, [projectId, loadSubcription]);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    if (searchParams.has("error")) {
      if (searchParams.get("error") === "y") {
        setNotPaid(true);
      } else {
        setNotPaid(false);
      }
      return;
    }
    if (
      subscription?.status ===
      ApiProjectSubscriptionStatus.PROJECT_SUBSCRIPTION_STATUS_CREATED
    ) {
      setNotPaid(true);
    }
  }, [subscription, search]);

  if (!subscription?.subscription) {
    return <Loading fullHeight />;
  }

  return (
    <CenterPage>
      {!notPaid ? (
        <Stack spacing={2} alignItems="center">
          <Typography variant="h1">Thank you</Typography>
          <Typography>
            Maybe we should say something about it might take a while, we have
            to wait for stripe to send webhook before we know if paid, although
            at the moment should not effect us?
          </Typography>

          <Box sx={{ width: 400 }}>
            <SubscriptionCard subscription={subscription?.subscription} />
          </Box>
        </Stack>
      ) : (
        <Alert severity="error">
          There was an issue with your payment, please contact your account
          manager
        </Alert>
      )}
    </CenterPage>
  );
}

export default ThankyouPage;
