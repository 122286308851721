import {
  Box,
  FormControlLabel,
  IconButton,
  Stack,
  styled,
  Switch,
  Typography,
} from "@mui/material";
import GlassCard from "Components/GlassCard/GlassCard";
import backgroundImageStat1 from "Assets/backgrounds/stat-1.jpg";
import backgroundImageStat2 from "Assets/backgrounds/stat-2.jpg";
import backgroundImageStat3 from "Assets/backgrounds/stat-3.jpg";
import backgroundImageStat4 from "Assets/backgrounds/stat-4.jpg";
import { formatFloat } from "Helpers/numbers";
import { Delete, Edit } from "@mui/icons-material";
import {
  ApiSimpleSubscription,
  ApiSubscriptionStatus,
  ApiSubscriptionTier,
} from "@incendium/api";

const StyledHeader = styled(Box)<{ tier?: ApiSubscriptionTier }>(
  ({ theme, tier }) => ({
    backgroundColor: "white",
    backgroundImage: `url(${
      tier === ApiSubscriptionTier.SUBSCRIPTION_TIER_START
        ? backgroundImageStat2
        : tier === ApiSubscriptionTier.SUBSCRIPTION_TIER_BASIC
        ? backgroundImageStat3
        : tier === ApiSubscriptionTier.SUBSCRIPTION_TIER_PRO
        ? backgroundImageStat4
        : backgroundImageStat1
    })`,
    backgroundSize: "cover",
    padding: theme.spacing(2),
    color: "white",
  })
);

interface ISubscriptionCardProps {
  subscription: ApiSimpleSubscription;
  onEdit?: (s: ApiSimpleSubscription) => void;
  onDelete?: (s: ApiSimpleSubscription) => void;
  onActivate?: (b: boolean, s: ApiSimpleSubscription) => void;
}

function SubscriptionCard({
  subscription,
  onEdit,
  onDelete,
  onActivate,
}: ISubscriptionCardProps) {
  return (
    <GlassCard boxProps={{ p: 0 }}>
      <StyledHeader height={200} tier={subscription.product?.tier}>
        <Typography color={"inherit"} variant="subtitle1" mb={1}>
          {subscription.name}
        </Typography>
        <Typography color={"inherit"} variant="h1" fontWeight={600}>
          ${formatFloat((subscription.price || 0) / 100)}
        </Typography>
        <Typography
          color={"inherit"}
          variant="subtitle1"
          mb={1}
          fontWeight={600}
        >
          (USD/month)
        </Typography>
        <Typography
          color={"inherit"}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "3",
            WebkitBoxOrient: "vertical",
          }}
        >
          {subscription.description}
        </Typography>
      </StyledHeader>
      {(onEdit || onDelete || onActivate) && (
        <Stack direction={"row"} px={2} py={1} justifyContent="space-between">
          <Stack direction={"row"}>
            {onEdit && (
              <IconButton
                disabled={
                  subscription.status ===
                  ApiSubscriptionStatus.SUBSCRIPTION_STATUS_ACTIVE
                }
                size="small"
                color="primary"
                onClick={() => onEdit(subscription)}
              >
                <Edit />
              </IconButton>
            )}
            {onDelete && (
              <IconButton
                disabled={
                  subscription.status ===
                  ApiSubscriptionStatus.SUBSCRIPTION_STATUS_ACTIVE
                }
                size="small"
                color="secondary"
                onClick={() => onDelete(subscription)}
              >
                <Delete />
              </IconButton>
            )}
          </Stack>
          {onActivate && (
            <FormControlLabel
              control={
                <Switch
                  disabled={
                    subscription.status ===
                      ApiSubscriptionStatus.SUBSCRIPTION_STATUS_ACTIVE ||
                    !subscription.contractId ||
                    !subscription.productId
                  }
                  checked={
                    subscription.status ===
                    ApiSubscriptionStatus.SUBSCRIPTION_STATUS_ACTIVE
                  }
                  onChange={(e, c) => {
                    onActivate(c, subscription);
                  }}
                />
              }
              label="Activate"
            />
          )}
        </Stack>
      )}
    </GlassCard>
  );
}

export default SubscriptionCard;
