import { Box, Typography } from "@mui/material";
import { Glass } from "Components/Glass";
import {
  ChartBuilderMap,
  ChartBuilderSnapshot,
  ChartBuuilderTypeTransitioning,
} from "features/chartLibrary";
import { StyledMiddleBox } from "Components/UI/StylesFlexBox";
import { useReportBuilderContext } from "Providers/ReportBuilderProvider";
import {
  AnalyticsCard,
  AnalyticsChartStatCard,
  hasAttributionMetric,
  useChartTypeTransition,
} from "features/analytics";
import { AnimatePresence } from "framer-motion";
import { memo, useCallback } from "react";
import { ApiChartTemplate, ApiChartType } from "@incendium/api";
import SetupNeeded from "Components/UI/SetupNeeded";

function ChartBuilder() {
  const { chart } = useReportBuilderContext();
  const transitioning = useChartTypeTransition(chart);

  const renderChart = useCallback(() => {
    if (chart.type === ApiChartType.STAT_CARD) {
      return <AnalyticsChartStatCard chart={chart} noToolbar />;
    }

    switch (chart.template) {
      case ApiChartTemplate.SNAPSHOT:
      case ApiChartTemplate.TREND:
      case ApiChartTemplate.TABLE:
      case ApiChartTemplate.MAP:
      case ApiChartTemplate.ATTRIBUTION:
        return <AnalyticsCard chart={chart} />;

      default:
        return (
          <SetupNeeded>
            <Typography variant="h6">No Chart available.</Typography>
          </SetupNeeded>
        );
    }
  }, [chart]);

  const renderBody = useCallback(() => {
    switch (chart.template) {
      case ApiChartTemplate.UNDEFINED:
      case ApiChartTemplate.SNAPSHOT:
      case ApiChartTemplate.TREND:
      case ApiChartTemplate.TABLE:
      case ApiChartTemplate.ATTRIBUTION:
        return <ChartBuilderSnapshot />;
      case ApiChartTemplate.MAP:
        return <ChartBuilderMap />;

      default:
        return <>not available</>;
    }
  }, [chart.template]);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: `60% 40%`,
        height: "100%",
      }}
    >
      <StyledMiddleBox p={5}>
        <Box
          sx={{
            height: hasAttributionMetric(chart) ? 565 : 530,
            display: "flex",
            justifyContent: "center",
            width: chart.type === ApiChartType.STAT_CARD ? 500 : 800,
            maxWidth: "100%",
          }}
        >
          <AnimatePresence>
            {transitioning ? <ChartBuuilderTypeTransitioning /> : renderChart()}
          </AnimatePresence>
        </Box>
      </StyledMiddleBox>

      <Glass square>
        <Box>{renderBody()}</Box>
      </Glass>
    </Box>
  );
}

export default memo(ChartBuilder);
