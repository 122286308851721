import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
} from "@incendium/api";
import { Box, Stack } from "@mui/material";
import ImpressionsByDayChart from "features/analytics/components/ImpressionsByDayChart";
import PrimaryTopMicroConversions from "features/analytics/components/PrimaryTopMicroConversions";
import { useMemo } from "react";
import {
  AnalyticsStatCard,
  JobInteractionsWheel,
  JobKeyStatsCard,
  percentageTopChangeByMetric,
  useFormatMetric,
} from "features/analytics";
import { StatCardTypography } from "Components/UI/StatCard";
import IncreaseDecrease from "Components/IncreaseDecrease/IncreaseDecrease";
import { AnalyticsSpacing } from "consts";

interface IJobSingleAndCategoryPageProps {
  dimension: ApiDimension;
  byDimension: string;
}

function JobSingleAndCategoryPage({
  dimension,
  byDimension,
}: IJobSingleAndCategoryPageProps) {
  const formatMetric = useFormatMetric();
  const attributes = useMemo(
    () => [
      {
        key: dimension,
        value: byDimension,
      },
    ],
    [dimension, byDimension]
  );

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: `repeat(18, 1fr)`,
          gridTemplateRows: `repeat(9, 1fr)`,
          rowGap: AnalyticsSpacing,
          height: 840,
          columnGap: AnalyticsSpacing,
        }}
      >
        <Box sx={{ gridArea: "1 / 1 / 10 / 5" }}>
          <Stack spacing={AnalyticsSpacing} sx={{ height: "100%" }}>
            <AnalyticsStatCard
              comparison
              chart={{
                name: "",
                dimension: [],
                template: ApiChartTemplate.SNAPSHOT,
                type: ApiChartType.GRAPH,
                attributes: [],
                yAxisKeys: [
                  {
                    key: "l",
                    fields: [ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT],
                  },
                ],
              }}
              setDirection={(data, compData) =>
                percentageTopChangeByMetric(
                  data,
                  compData || [],
                  ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT
                ) > 0
                  ? "up"
                  : "down"
              }
              renderBody={(data, compData) => {
                const change = percentageTopChangeByMetric(
                  data,
                  compData || [],
                  ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT
                );
                return (
                  <>
                    <StatCardTypography size="small" opactity={0.8}>
                      Total Applications
                    </StatCardTypography>
                    <StatCardTypography size="large">
                      {formatMetric(
                        ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT,
                        Number(
                          data[0][
                            ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT
                          ] || 0
                        )
                      )}
                    </StatCardTypography>
                    <StatCardTypography size="small">
                      <IncreaseDecrease
                        direction={change >= 0 ? "up" : "down"}
                        value={change}
                        size={"large"}
                        fontSize={22}
                      />
                    </StatCardTypography>
                  </>
                );
              }}
            />

            <JobKeyStatsCard
              jobDimension={dimension}
              selectedDimension={byDimension}
            />
          </Stack>
        </Box>
        <Box sx={{ gridArea: "1 / 5 / 10 / 13" }}>
          <JobInteractionsWheel attributes={attributes} />
        </Box>
        <Box sx={{ gridArea: "1 / 13 / 5 / 19" }}>
          <ImpressionsByDayChart
            dimension={dimension}
            impressionField={ApiMetric.METRIC_JOB_IMPRESSION_COUNT}
            type="job"
            selected={byDimension}
          />
        </Box>
        <Box sx={{ gridArea: "5 / 13 / 10/ 19" }}>
          <PrimaryTopMicroConversions
            dimension={dimension}
            byDimension={byDimension || ""}
            dimensionString={`job`}
          />
        </Box>
      </Box>
    </>
  );
}

export default JobSingleAndCategoryPage;
