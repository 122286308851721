import {
  ApiChannel,
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
  ApiYAxisChartType,
} from "@incendium/api";
import { Grid, Portal, Stack } from "@mui/material";
import { channelService } from "Apis";
import CreateNameDialog from "Components/CreateNameDialog/CreateNameDialog";
import { TypographyHelp } from "Components/UI/TypographyHelp";
import {
  AnalyticsCard,
  AnalyticsStatCard,
  NOT_SET,
  useFormatMetric,
} from "features/analytics";
import { useChannels } from "Hooks/useChannels";
import { useSelectedProject } from "Hooks/useSelectedProject";
import produce from "immer";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { StatCardTypography } from "Components/UI/StatCard";
import { useConfirmation } from "Hooks/useConfirmation";
import { ChannelsList, saveChannel } from "features/channels";

export const ChannelsPage = () => {
  const { selectedProject } = useSelectedProject();
  const { channels, setChannels } = useChannels();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const formatMetric = useFormatMetric();
  const handleDelete = useConfirmation();

  const newChannel = () => {
    setOpen(true);
  };

  const editChannel = (channel: ApiChannel) => {
    history.push(`channels/${channel.id}`);
  };

  const deleteChannel = (channel: ApiChannel) => {
    handleDelete({
      title: `Are you sure you want to delete this channel`,
      body: `This action can not be undone`,
      callback: async () => {
        await channelService.channelServiceDeleteChannel({
          projectId: selectedProject?.id as number,
          channelId: channel.id as number,
        });

        setChannels(
          produce(channels, (draft) => {
            const idx = channels.findIndex((ch) => ch.id === channel.id);
            if (idx !== -1) draft.splice(idx, 1);
          })
        );

        return `${channel.name || ""} Deleted`;
      },
    });
  };

  const onSave = async (name: string) => {
    try {
      const res = await saveChannel(selectedProject!.id! as number, { name });

      setChannels(
        produce(channels, (draft) => {
          draft.push(res);
        })
      );

      enqueueSnackbar(`${name} Created, redirecting`, {
        variant: "success",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      editChannel(res);
    } catch (error) {
      enqueueSnackbar(`The was an internal error, please try again`, {
        variant: "error",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    }
  };

  return (
    <>
      {selectedProject ? (
        <>
          <Portal container={() => document.getElementById("pageTitle")}>
            <TypographyHelp
              variant="h1"
              text={`${selectedProject.name} Channels`}
              placement={"right"}
              tooltip={`
                Channels group traffic into buckets based on rules so that we can do performance analysis in analytics at a top level. 
                They follow a cascading priority, meaning that if a pageview matches a rule set with priority 2 and another rule set with priority 5, 
                the rule set with priority 2 will take precedent.`}
            />
          </Portal>
          <Grid container spacing={4}>
            <Grid item xs={9}>
              <ChannelsList
                onEdit={editChannel}
                onDelete={deleteChannel}
                project={selectedProject}
                channels={channels || []}
                setChannels={setChannels}
                onClick={newChannel}
              />
            </Grid>
            <Grid item xs={3}>
              <Stack spacing={4}>
                <AnalyticsCard
                  overrideDate={{
                    lastNDays: 365,
                    from: undefined,
                    to: undefined,
                  }}
                  height={278}
                  chart={{
                    name: "Unmatched Sources In The Last Year",
                    dimension: [ApiDimension.DIMENSION_SOURCE],
                    template: ApiChartTemplate.SNAPSHOT,
                    type: ApiChartType.PIE,
                    includeEmpty: true,
                    attributes: [
                      {
                        key: ApiDimension.DIMENSION_CHANNEL,
                        value: NOT_SET,
                      },
                    ],
                    yAxisKeys: [
                      {
                        key: "l",
                        fields: [ApiMetric.METRIC_LEADS_COUNT],
                      },
                    ],
                    displayOptions: {
                      noFilters: true,
                      showTotals: true,
                    },
                  }}
                />

                <AnalyticsStatCard
                  chartOutput={{
                    lastNDays: 365,
                    from: undefined,
                    to: undefined,
                  }}
                  chart={{
                    name: "",
                    dimension: [ApiDimension.DIMENSION_SOURCE],
                    template: ApiChartTemplate.SNAPSHOT,
                    type: ApiChartType.GRAPH,
                    attributes: [
                      {
                        key: ApiDimension.DIMENSION_CHANNEL,
                        value: NOT_SET,
                      },
                    ],
                    yAxisKeys: [
                      {
                        key: "l",
                        fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                      },
                    ],
                  }}
                  renderBody={(data) => (
                    <>
                      <StatCardTypography size="xs" opactity={0.8}>
                        Unmatched Source With Most Sessions
                      </StatCardTypography>
                      <StatCardTypography mt={1} size="medium" lines={1}>
                        {data[0]?.name}
                      </StatCardTypography>
                    </>
                  )}
                />
                <AnalyticsStatCard
                  chartOutput={{
                    lastNDays: 365,
                    from: undefined,
                    to: undefined,
                  }}
                  chart={{
                    name: "",
                    dimension: [ApiDimension.DIMENSION_MEDIUM],
                    template: ApiChartTemplate.SNAPSHOT,
                    type: ApiChartType.GRAPH,
                    attributes: [
                      {
                        key: ApiDimension.DIMENSION_CHANNEL,
                        value: NOT_SET,
                      },
                    ],
                    yAxisKeys: [
                      {
                        key: "l",
                        fields: [ApiMetric.METRIC_LEADS_COUNT],
                      },
                    ],
                  }}
                  renderBody={(data) => (
                    <>
                      <StatCardTypography size="xs" opactity={0.8}>
                        Unmatched Medium With Most Sessions
                      </StatCardTypography>
                      <StatCardTypography mt={1} size="medium" lines={1}>
                        {data[0]?.name}
                      </StatCardTypography>
                    </>
                  )}
                />
                <AnalyticsStatCard
                  chartOutput={{
                    lastNDays: 365,
                    from: undefined,
                    to: undefined,
                  }}
                  chart={{
                    name: "",
                    dimension: [],
                    template: ApiChartTemplate.SNAPSHOT,
                    type: ApiChartType.GRAPH,
                    attributes: [
                      {
                        key: ApiDimension.DIMENSION_CHANNEL,
                        value: NOT_SET,
                      },
                    ],
                    yAxisKeys: [
                      {
                        key: "l",
                        fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                      },
                    ],
                  }}
                  renderBody={(data) => (
                    <>
                      <StatCardTypography size="xs" opactity={0.8}>
                        Number of Sessions not in a channel in the last year
                      </StatCardTypography>
                      <StatCardTypography size="large" lines={1}>
                        {formatMetric(
                          ApiMetric.METRIC_SESSIONS_COUNT,
                          Number(data[0][ApiMetric.METRIC_SESSIONS_COUNT])
                        )}{" "}
                      </StatCardTypography>
                    </>
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={6} sx={{ minHeight: 445 }}>
              <AnalyticsCard
                overrideDate={{
                  lastNDays: 365,
                  from: undefined,
                  to: undefined,
                }}
                chart={{
                  name: "Matched Channels in Last Year",
                  dimension: [
                    ApiDimension.DIMENSION_CHANNEL,
                    ApiDimension.DIMENSION_SOURCE,
                    ApiDimension.DIMENSION_MEDIUM,
                  ],
                  template: ApiChartTemplate.TABLE,
                  type: ApiChartType.TABLE,
                  orderBy: `${ApiDimension.DIMENSION_CHANNEL} asc`,
                  includeEmpty: true,
                  attributes: [],
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [
                        ApiMetric.METRIC_LEADS_COUNT,
                        ApiMetric.METRIC_SESSIONS_COUNT,
                      ],
                    },
                  ],
                  displayOptions: {
                    noFilters: true,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} sx={{ minHeight: 445 }}>
              <AnalyticsCard
                overrideDate={{
                  lastNDays: 365,
                  from: undefined,
                  to: undefined,
                }}
                chart={{
                  name: "Matched Channels in Last Year",
                  dimension: [
                    ApiDimension.DIMENSION_SESSION_START_BY_MONTH,
                    ApiDimension.DIMENSION_CHANNEL,
                  ],
                  template: ApiChartTemplate.TREND,
                  type: ApiChartType.GRAPH,
                  includeEmpty: true,
                  attributes: [],
                  yAxisKeys: [
                    {
                      key: "l",
                      stackId: "l",
                      fields: [ApiMetric.METRIC_LEADS_COUNT],
                      chart: {
                        [ApiMetric.METRIC_LEADS_COUNT]: ApiYAxisChartType.BAR,
                      },
                    },
                  ],
                  displayOptions: {
                    noFilters: true,
                  },
                }}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        "No Project selected"
      )}
      <CreateNameDialog
        open={open}
        setOpen={setOpen}
        onSaved={onSave}
        title="Add Channel Name"
      />
    </>
  );
};
