/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApiChartPayloadToJSON, ApiChartResponseFromJSON, ApiListChartsResponseFromJSON, } from '../models';
/**
 *
 */
export class ChartServiceApi extends runtime.BaseAPI {
    /**
     */
    chartServiceCreateChartRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling chartServiceCreateChart.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling chartServiceCreateChart.');
            }
            const queryParameters = {};
            if (requestParameters.chartId !== undefined) {
                queryParameters['chartId'] = requestParameters.chartId;
            }
            if (requestParameters.queryToken !== undefined) {
                queryParameters['query.token'] = requestParameters.queryToken;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/chart/v1/projects/{projectId}/charts`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiChartPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiChartResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    chartServiceCreateChart(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.chartServiceCreateChartRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    chartServiceDeleteChartRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling chartServiceDeleteChart.');
            }
            if (requestParameters.chartId === null || requestParameters.chartId === undefined) {
                throw new runtime.RequiredError('chartId', 'Required parameter requestParameters.chartId was null or undefined when calling chartServiceDeleteChart.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadType !== undefined) {
                queryParameters['payload.type'] = requestParameters.payloadType;
            }
            if (requestParameters.payloadDimension) {
                queryParameters['payload.dimension'] = requestParameters.payloadDimension;
            }
            if (requestParameters.payloadOrderBy !== undefined) {
                queryParameters['payload.orderBy'] = requestParameters.payloadOrderBy;
            }
            if (requestParameters.payloadLimit !== undefined) {
                queryParameters['payload.limit'] = requestParameters.payloadLimit;
            }
            if (requestParameters.payloadTemplate !== undefined) {
                queryParameters['payload.template'] = requestParameters.payloadTemplate;
            }
            if (requestParameters.payloadDisplayOptionsRowsPerPage !== undefined) {
                queryParameters['payload.displayOptions.rowsPerPage'] = requestParameters.payloadDisplayOptionsRowsPerPage;
            }
            if (requestParameters.payloadDisplayOptionsNoPagination !== undefined) {
                queryParameters['payload.displayOptions.noPagination'] = requestParameters.payloadDisplayOptionsNoPagination;
            }
            if (requestParameters.payloadDisplayOptionsAvailableMetrics) {
                queryParameters['payload.displayOptions.availableMetrics'] = requestParameters.payloadDisplayOptionsAvailableMetrics;
            }
            if (requestParameters.payloadDisplayOptionsPieTotalText !== undefined) {
                queryParameters['payload.displayOptions.pieTotalText'] = requestParameters.payloadDisplayOptionsPieTotalText;
            }
            if (requestParameters.payloadDisplayOptionsNoLegend !== undefined) {
                queryParameters['payload.displayOptions.noLegend'] = requestParameters.payloadDisplayOptionsNoLegend;
            }
            if (requestParameters.payloadDisplayOptionsNoReorder !== undefined) {
                queryParameters['payload.displayOptions.noReorder'] = requestParameters.payloadDisplayOptionsNoReorder;
            }
            if (requestParameters.payloadDisplayOptionsMetricsOrder) {
                queryParameters['payload.displayOptions.metricsOrder'] = requestParameters.payloadDisplayOptionsMetricsOrder;
            }
            if (requestParameters.payloadDisplayOptionsNoFilters !== undefined) {
                queryParameters['payload.displayOptions.noFilters'] = requestParameters.payloadDisplayOptionsNoFilters;
            }
            if (requestParameters.payloadDisplayOptionsShowLabels !== undefined) {
                queryParameters['payload.displayOptions.showLabels'] = requestParameters.payloadDisplayOptionsShowLabels;
            }
            if (requestParameters.payloadDisplayOptionsAvailableDimensions) {
                queryParameters['payload.displayOptions.availableDimensions'] = requestParameters.payloadDisplayOptionsAvailableDimensions;
            }
            if (requestParameters.payloadDisplayOptionsFilterDropdowns) {
                queryParameters['payload.displayOptions.filterDropdowns'] = requestParameters.payloadDisplayOptionsFilterDropdowns;
            }
            if (requestParameters.payloadDisplayOptionsLayout !== undefined) {
                queryParameters['payload.displayOptions.layout'] = requestParameters.payloadDisplayOptionsLayout;
            }
            if (requestParameters.payloadDisplayOptionsNoTitle !== undefined) {
                queryParameters['payload.displayOptions.noTitle'] = requestParameters.payloadDisplayOptionsNoTitle;
            }
            if (requestParameters.payloadDisplayOptionsNoAttributionToggle !== undefined) {
                queryParameters['payload.displayOptions.noAttributionToggle'] = requestParameters.payloadDisplayOptionsNoAttributionToggle;
            }
            if (requestParameters.payloadDisplayOptionsNoAxis !== undefined) {
                queryParameters['payload.displayOptions.noAxis'] = requestParameters.payloadDisplayOptionsNoAxis;
            }
            if (requestParameters.payloadDisplayOptionsPieTotalFontSize !== undefined) {
                queryParameters['payload.displayOptions.pieTotalFontSize'] = requestParameters.payloadDisplayOptionsPieTotalFontSize;
            }
            if (requestParameters.payloadDisplayOptionsShowTotals !== undefined) {
                queryParameters['payload.displayOptions.showTotals'] = requestParameters.payloadDisplayOptionsShowTotals;
            }
            if (requestParameters.payloadDisplayOptionsStatDesignIndex !== undefined) {
                queryParameters['payload.displayOptions.statDesignIndex'] = requestParameters.payloadDisplayOptionsStatDesignIndex;
            }
            if (requestParameters.payloadDisplayOptionsStatTitle !== undefined) {
                queryParameters['payload.displayOptions.statTitle'] = requestParameters.payloadDisplayOptionsStatTitle;
            }
            if (requestParameters.payloadDisplayOptionsStatTitle2 !== undefined) {
                queryParameters['payload.displayOptions.statTitle2'] = requestParameters.payloadDisplayOptionsStatTitle2;
            }
            if (requestParameters.payloadDisplayOptionsStatNoBg !== undefined) {
                queryParameters['payload.displayOptions.statNoBg'] = requestParameters.payloadDisplayOptionsStatNoBg;
            }
            if (requestParameters.payloadDisplayOptionsFunnelUseLogFn !== undefined) {
                queryParameters['payload.displayOptions.funnelUseLogFn'] = requestParameters.payloadDisplayOptionsFunnelUseLogFn;
            }
            if (requestParameters.payloadDisplayOptionsFullAttributesList) {
                queryParameters['payload.displayOptions.fullAttributesList'] = requestParameters.payloadDisplayOptionsFullAttributesList;
            }
            if (requestParameters.payloadDisplayOptionsTablePinAt !== undefined) {
                queryParameters['payload.displayOptions.tablePinAt'] = requestParameters.payloadDisplayOptionsTablePinAt;
            }
            if (requestParameters.payloadDisplayOptionsDisabledMetricClick !== undefined) {
                queryParameters['payload.displayOptions.disabledMetricClick'] = requestParameters.payloadDisplayOptionsDisabledMetricClick;
            }
            if (requestParameters.payloadCrawler !== undefined) {
                queryParameters['payload.crawler'] = requestParameters.payloadCrawler;
            }
            if (requestParameters.payloadComparison !== undefined) {
                queryParameters['payload.comparison'] = requestParameters.payloadComparison;
            }
            if (requestParameters.payloadIncludeEmpty !== undefined) {
                queryParameters['payload.includeEmpty'] = requestParameters.payloadIncludeEmpty;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadIncludeSubdomains !== undefined) {
                queryParameters['payload.includeSubdomains'] = requestParameters.payloadIncludeSubdomains;
            }
            if (requestParameters.payloadWithTotals !== undefined) {
                queryParameters['payload.withTotals'] = requestParameters.payloadWithTotals;
            }
            if (requestParameters.queryToken !== undefined) {
                queryParameters['query.token'] = requestParameters.queryToken;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/chart/v1/projects/{projectId}/charts/{chartId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"chartId"}}`, encodeURIComponent(String(requestParameters.chartId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    chartServiceDeleteChart(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.chartServiceDeleteChartRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    chartServiceListChartsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling chartServiceListCharts.');
            }
            const queryParameters = {};
            if (requestParameters.pageSize !== undefined) {
                queryParameters['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.pageToken !== undefined) {
                queryParameters['pageToken'] = requestParameters.pageToken;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/chart/v1/projects/{projectId}/charts`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListChartsResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    chartServiceListCharts(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.chartServiceListChartsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    chartServiceReadChartRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling chartServiceReadChart.');
            }
            if (requestParameters.chartId === null || requestParameters.chartId === undefined) {
                throw new runtime.RequiredError('chartId', 'Required parameter requestParameters.chartId was null or undefined when calling chartServiceReadChart.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadType !== undefined) {
                queryParameters['payload.type'] = requestParameters.payloadType;
            }
            if (requestParameters.payloadDimension) {
                queryParameters['payload.dimension'] = requestParameters.payloadDimension;
            }
            if (requestParameters.payloadOrderBy !== undefined) {
                queryParameters['payload.orderBy'] = requestParameters.payloadOrderBy;
            }
            if (requestParameters.payloadLimit !== undefined) {
                queryParameters['payload.limit'] = requestParameters.payloadLimit;
            }
            if (requestParameters.payloadTemplate !== undefined) {
                queryParameters['payload.template'] = requestParameters.payloadTemplate;
            }
            if (requestParameters.payloadDisplayOptionsRowsPerPage !== undefined) {
                queryParameters['payload.displayOptions.rowsPerPage'] = requestParameters.payloadDisplayOptionsRowsPerPage;
            }
            if (requestParameters.payloadDisplayOptionsNoPagination !== undefined) {
                queryParameters['payload.displayOptions.noPagination'] = requestParameters.payloadDisplayOptionsNoPagination;
            }
            if (requestParameters.payloadDisplayOptionsAvailableMetrics) {
                queryParameters['payload.displayOptions.availableMetrics'] = requestParameters.payloadDisplayOptionsAvailableMetrics;
            }
            if (requestParameters.payloadDisplayOptionsPieTotalText !== undefined) {
                queryParameters['payload.displayOptions.pieTotalText'] = requestParameters.payloadDisplayOptionsPieTotalText;
            }
            if (requestParameters.payloadDisplayOptionsNoLegend !== undefined) {
                queryParameters['payload.displayOptions.noLegend'] = requestParameters.payloadDisplayOptionsNoLegend;
            }
            if (requestParameters.payloadDisplayOptionsNoReorder !== undefined) {
                queryParameters['payload.displayOptions.noReorder'] = requestParameters.payloadDisplayOptionsNoReorder;
            }
            if (requestParameters.payloadDisplayOptionsMetricsOrder) {
                queryParameters['payload.displayOptions.metricsOrder'] = requestParameters.payloadDisplayOptionsMetricsOrder;
            }
            if (requestParameters.payloadDisplayOptionsNoFilters !== undefined) {
                queryParameters['payload.displayOptions.noFilters'] = requestParameters.payloadDisplayOptionsNoFilters;
            }
            if (requestParameters.payloadDisplayOptionsShowLabels !== undefined) {
                queryParameters['payload.displayOptions.showLabels'] = requestParameters.payloadDisplayOptionsShowLabels;
            }
            if (requestParameters.payloadDisplayOptionsAvailableDimensions) {
                queryParameters['payload.displayOptions.availableDimensions'] = requestParameters.payloadDisplayOptionsAvailableDimensions;
            }
            if (requestParameters.payloadDisplayOptionsFilterDropdowns) {
                queryParameters['payload.displayOptions.filterDropdowns'] = requestParameters.payloadDisplayOptionsFilterDropdowns;
            }
            if (requestParameters.payloadDisplayOptionsLayout !== undefined) {
                queryParameters['payload.displayOptions.layout'] = requestParameters.payloadDisplayOptionsLayout;
            }
            if (requestParameters.payloadDisplayOptionsNoTitle !== undefined) {
                queryParameters['payload.displayOptions.noTitle'] = requestParameters.payloadDisplayOptionsNoTitle;
            }
            if (requestParameters.payloadDisplayOptionsNoAttributionToggle !== undefined) {
                queryParameters['payload.displayOptions.noAttributionToggle'] = requestParameters.payloadDisplayOptionsNoAttributionToggle;
            }
            if (requestParameters.payloadDisplayOptionsNoAxis !== undefined) {
                queryParameters['payload.displayOptions.noAxis'] = requestParameters.payloadDisplayOptionsNoAxis;
            }
            if (requestParameters.payloadDisplayOptionsPieTotalFontSize !== undefined) {
                queryParameters['payload.displayOptions.pieTotalFontSize'] = requestParameters.payloadDisplayOptionsPieTotalFontSize;
            }
            if (requestParameters.payloadDisplayOptionsShowTotals !== undefined) {
                queryParameters['payload.displayOptions.showTotals'] = requestParameters.payloadDisplayOptionsShowTotals;
            }
            if (requestParameters.payloadDisplayOptionsStatDesignIndex !== undefined) {
                queryParameters['payload.displayOptions.statDesignIndex'] = requestParameters.payloadDisplayOptionsStatDesignIndex;
            }
            if (requestParameters.payloadDisplayOptionsStatTitle !== undefined) {
                queryParameters['payload.displayOptions.statTitle'] = requestParameters.payloadDisplayOptionsStatTitle;
            }
            if (requestParameters.payloadDisplayOptionsStatTitle2 !== undefined) {
                queryParameters['payload.displayOptions.statTitle2'] = requestParameters.payloadDisplayOptionsStatTitle2;
            }
            if (requestParameters.payloadDisplayOptionsStatNoBg !== undefined) {
                queryParameters['payload.displayOptions.statNoBg'] = requestParameters.payloadDisplayOptionsStatNoBg;
            }
            if (requestParameters.payloadDisplayOptionsFunnelUseLogFn !== undefined) {
                queryParameters['payload.displayOptions.funnelUseLogFn'] = requestParameters.payloadDisplayOptionsFunnelUseLogFn;
            }
            if (requestParameters.payloadDisplayOptionsFullAttributesList) {
                queryParameters['payload.displayOptions.fullAttributesList'] = requestParameters.payloadDisplayOptionsFullAttributesList;
            }
            if (requestParameters.payloadDisplayOptionsTablePinAt !== undefined) {
                queryParameters['payload.displayOptions.tablePinAt'] = requestParameters.payloadDisplayOptionsTablePinAt;
            }
            if (requestParameters.payloadDisplayOptionsDisabledMetricClick !== undefined) {
                queryParameters['payload.displayOptions.disabledMetricClick'] = requestParameters.payloadDisplayOptionsDisabledMetricClick;
            }
            if (requestParameters.payloadCrawler !== undefined) {
                queryParameters['payload.crawler'] = requestParameters.payloadCrawler;
            }
            if (requestParameters.payloadComparison !== undefined) {
                queryParameters['payload.comparison'] = requestParameters.payloadComparison;
            }
            if (requestParameters.payloadIncludeEmpty !== undefined) {
                queryParameters['payload.includeEmpty'] = requestParameters.payloadIncludeEmpty;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadIncludeSubdomains !== undefined) {
                queryParameters['payload.includeSubdomains'] = requestParameters.payloadIncludeSubdomains;
            }
            if (requestParameters.payloadWithTotals !== undefined) {
                queryParameters['payload.withTotals'] = requestParameters.payloadWithTotals;
            }
            if (requestParameters.queryToken !== undefined) {
                queryParameters['query.token'] = requestParameters.queryToken;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/chart/v1/projects/{projectId}/charts/{chartId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"chartId"}}`, encodeURIComponent(String(requestParameters.chartId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiChartResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    chartServiceReadChart(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.chartServiceReadChartRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    chartServiceUpdateChartRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling chartServiceUpdateChart.');
            }
            if (requestParameters.chartId === null || requestParameters.chartId === undefined) {
                throw new runtime.RequiredError('chartId', 'Required parameter requestParameters.chartId was null or undefined when calling chartServiceUpdateChart.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling chartServiceUpdateChart.');
            }
            const queryParameters = {};
            if (requestParameters.queryToken !== undefined) {
                queryParameters['query.token'] = requestParameters.queryToken;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/chart/v1/projects/{projectId}/charts/{chartId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"chartId"}}`, encodeURIComponent(String(requestParameters.chartId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiChartPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiChartResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    chartServiceUpdateChart(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.chartServiceUpdateChartRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
/**
    * @export
    * @enum {string}
    */
export var ChartServiceDeleteChartPayloadTypeEnum;
(function (ChartServiceDeleteChartPayloadTypeEnum) {
    ChartServiceDeleteChartPayloadTypeEnum["UNDEFINED"] = "CHART_TYPE_UNDEFINED";
    ChartServiceDeleteChartPayloadTypeEnum["PIE"] = "CHART_TYPE_PIE";
    ChartServiceDeleteChartPayloadTypeEnum["GRAPH"] = "CHART_TYPE_GRAPH";
    ChartServiceDeleteChartPayloadTypeEnum["RADAR"] = "CHART_TYPE_RADAR";
    ChartServiceDeleteChartPayloadTypeEnum["TABLE"] = "CHART_TYPE_TABLE";
    ChartServiceDeleteChartPayloadTypeEnum["BUBBLE"] = "CHART_TYPE_BUBBLE";
    ChartServiceDeleteChartPayloadTypeEnum["TREE_MAP"] = "CHART_TYPE_TREE_MAP";
    ChartServiceDeleteChartPayloadTypeEnum["STAT_CARD"] = "CHART_TYPE_STAT_CARD";
    ChartServiceDeleteChartPayloadTypeEnum["FUNNEL"] = "CHART_TYPE_FUNNEL";
})(ChartServiceDeleteChartPayloadTypeEnum || (ChartServiceDeleteChartPayloadTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ChartServiceDeleteChartPayloadTemplateEnum;
(function (ChartServiceDeleteChartPayloadTemplateEnum) {
    ChartServiceDeleteChartPayloadTemplateEnum["UNDEFINED"] = "CHART_TEMPLATE_UNDEFINED";
    ChartServiceDeleteChartPayloadTemplateEnum["TREND"] = "CHART_TEMPLATE_TREND";
    ChartServiceDeleteChartPayloadTemplateEnum["MAP"] = "CHART_TEMPLATE_MAP";
    ChartServiceDeleteChartPayloadTemplateEnum["SNAPSHOT"] = "CHART_TEMPLATE_SNAPSHOT";
    ChartServiceDeleteChartPayloadTemplateEnum["TABLE"] = "CHART_TEMPLATE_TABLE";
    ChartServiceDeleteChartPayloadTemplateEnum["ATTRIBUTION"] = "CHART_TEMPLATE_ATTRIBUTION";
})(ChartServiceDeleteChartPayloadTemplateEnum || (ChartServiceDeleteChartPayloadTemplateEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum;
(function (ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum) {
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_NOT_SET"] = "MetricNotSet";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_VIEWS_COUNT"] = "MetricPageViewsCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SESSIONS_COUNT"] = "MetricSessionsCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_LEADS_COUNT"] = "MetricLeadsCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_NEW_LEADS_COUNT"] = "MetricNewLeadsCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_LEADS_COUNT"] = "MetricReturningLeadsCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_REVENUE"] = "MetricSessionRevenue";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_COUNT_PER_LEAD"] = "MetricSessionCountPerLead";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_NEW_SESSION_COUNT"] = "MetricNewSessionCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_SESSION_COUNT"] = "MetricReturningSessionCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_PAGEVIEW_COUNT"] = "MetricSessionPageviewCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_PAGEVIEWS_PER_SESSION"] = "MetricAveragePageviewsPerSession";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_SESSION_DURATION"] = "MetricAverageSessionDuration";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_SESSION_COUNT"] = "MetricEffectiveSessionCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_SESSION_COUNT_PER_LEAD"] = "MetricEffectiveSessionCountPerLead";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_SESSION_RATE"] = "MetricEffectiveSessionRate";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_PAGEVIEW_COUNT"] = "MetricEffectivePageviewCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_PAGEVIEW_COUNT_PER_LEAD"] = "MetricEffectivePageviewCountPerLead";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_PAGEVIEW_RATE"] = "MetricEffectivePageviewRate";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_TIME_ON_PAGE"] = "MetricAverageTimeOnPage";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_VIEWED_PERCENTAGE"] = "MetricAverageViewedPercentage";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_SCROLL_PERCENTAGE"] = "MetricAverageScrollPercentage";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_SCROLL_SPEED"] = "MetricAverageScrollSpeed";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_LEAD_PERCENTAGE"] = "MetricReturningLeadPercentage";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_SESSION_TO_CONVERSION_RATE"] = "MetricEffectiveSessionToConversionRate";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_MACRO_CONVERSION_COUNT"] = "MetricSessionMacroConversionCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_MICRO_CONVERSION_COUNT"] = "MetricSessionMicroConversionCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_MACRO_CONVERSION_RATE"] = "MetricSessionMacroConversionRate";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_MICRO_CONVERSION_RATE"] = "MetricSessionMicroConversionRate";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRICMACRO_CONVERSION_COUNT_PER_LEAD"] = "MetricmacroConversionCountPerLead";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRICMICRO_CONVERSION_COUNT_PER_LEAD"] = "MetricmicroConversionCountPerLead";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRICEFFECTIVE_PAGEVIEW_TO_CONVERSION_RATE"] = "MetriceffectivePageviewToConversionRate";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_MACRO_CONVERSION_COUNT"] = "MetricPageMacroConversionCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_MICRO_CONVERSION_COUNT"] = "MetricPageMicroConversionCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_MACRO_CONVERSION_RATE"] = "MetricPageMacroConversionRate";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_MICRO_CONVERSION_RATE"] = "MetricPageMicroConversionRate";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_LEAD_MACRO_CONVERSION_PERCENTAGE"] = "MetricLeadMacroConversionPercentage";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_EFFECTIVE_PAGEVIEW_COUNT_DURING_SESSION"] = "MetricAverageEffectivePageviewCountDuringSession";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_EFFECTIVE_PAGEVIEW_RATE_DURING_SESSION"] = "MetricAverageEffectivePageviewRateDuringSession";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_TIME_ON_PAGE_DURING_SESSION"] = "MetricAverageTimeOnPageDuringSession";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_VIEWED_PERCENTAGE_DURING_SESSION"] = "MetricAverageViewedPercentageDuringSession";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_SCROLL_PERCENTAGE_DURING_SESSION"] = "MetricAverageScrollPercentageDuringSession";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_SCROLL_SPEED_DURING_SESSION"] = "MetricAverageScrollSpeedDuringSession";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_NEW_CUSTOMER_COUNT"] = "MetricNewCustomerCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_SALES_COUNT"] = "MetricSessionSalesCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SALES_REVENUE"] = "MetricSalesRevenue";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_VISIT_TO_PURCHASE_RATE"] = "MetricVisitToPurchaseRate";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_SALE_VALUE"] = "MetricAverageSaleValue";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_FIRST_VISIT_SESSION_MACRO_CONVERSION_COUNT"] = "MetricFirstVisitSessionMacroConversionCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_THIRTY_DAY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricThirtyDaySessionMacroConversionCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SIXTY_DAY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricSixtyDaySessionMacroConversionCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_NINETY_DAY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricNinetyDaySessionMacroConversionCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_THIRTY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricThirtyDaySessionMacroConversionCountAdditional";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SIXTY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricSixtyDaySessionMacroConversionCountAdditional";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_NINETY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricNinetyDaySessionMacroConversionCountAdditional";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_FIRST_VISIT_SESSION_SALES_COUNT"] = "MetricFirstVisitSessionSalesCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_THIRTY_DAY_SESSION_SALES_COUNT"] = "MetricThirtyDaySessionSalesCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SIXTY_DAY_SESSION_SALES_COUNT"] = "MetricSixtyDaySessionSalesCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_NINETY_DAY_SESSION_SALES_COUNT"] = "MetricNinetyDaySessionSalesCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_THIRTY_DAY_SESSION_SALES_COUNT_ADDITIONAL"] = "MetricThirtyDaySessionSalesCountAdditional";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SIXTY_DAY_SESSION_SALES_COUNT_ADDITIONAL"] = "MetricSixtyDaySessionSalesCountAdditional";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_NINETY_DAY_SESSION_SALES_COUNT_ADDITIONAL"] = "MetricNinetyDaySessionSalesCountAdditional";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_FIRST_VISIT_PAGE_MACRO_CONVERSION_COUNT"] = "MetricFirstVisitPageMacroConversionCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRI_THIRTY_DAY_MACRO_CONVERSION_COUNT"] = "MetriThirtyDayMacroConversionCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SIXTY_DAY_MACRO_CONVERSION_COUNT"] = "MetricSixtyDayMacroConversionCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_NINETY_DAY_MACRO_CONVERSION_COUNT"] = "MetricNinetyDayMacroConversionCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_THIRTY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricThirtyDayMacroConversionCountAdditional";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SIXTY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricSixtyDayMacroConversionCountAdditional";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_NINETY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricNinetyDayMacroConversionCountAdditional";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_REVENUE"] = "MetricProductRevenue";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_NINETY_DAY_CUSTOMER_COUNT"] = "MetricReturningNinetyDayCustomerCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_NINETY_DAY_CUSTOMER_PERCENTAGE"] = "MetricReturningNinetyDayCustomerPercentage";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_PAGEVIEW_COUNT"] = "MetricProductPageviewCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_JOB_PAGEVIEW_COUNT"] = "MetricJobPageviewCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_LANDING_SESSION_COUNT"] = "MetricLandingSessionCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_CRAWLER_VISIT_COUNT"] = "MetricCrawlerVisitCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_OUT_OF_STOCK_PAGEVIEW_COUNT"] = "MetricOutOfStockPageviewCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_OUT_OF_STOCK_PAGES_COUNT"] = "MetricOutOfStockPagesCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_DAYS_TO_SECOND_VISIT"] = "MetricAverageDaysToSecondVisit";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_JOB_APPLICATIONS_COUNT"] = "MetricSessionJobApplicationsCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_PAGEVIEW_RATE_RANK"] = "MetricEffectivePageviewRateRank";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_JOB_APPLY_CLICK_COUNT"] = "MetricSessionJobApplyClickCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_ADD_TO_CART_CLICK_COUNT"] = "MetricSessionAddToCartClickCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_ADD_TO_CART_CLICK_RATE"] = "MetricSessionAddToCartClickRate";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_JOB_APPLICATIONS_COUNT"] = "MetricPageJobApplicationsCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_JOB_APPLY_CLICK_COUNT"] = "MetricPageJobApplyClickCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_ADD_TO_CART_CLICK_COUNT"] = "MetricPageAddToCartClickCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_ADD_TO_CART_CLICK_RATE"] = "MetricPageAddToCartClickRate";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_CLICK_THROUGH_COUNT"] = "MetricProductClickThroughCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_IMPRESSION_COUNT"] = "MetricProductImpressionCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_CUSTOMER_COUNT"] = "MetricReturningCustomerCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_DESKTOP_SESSION_COUNT"] = "MetricDesktopSessionCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_MOBILE_SESSION_COUNT"] = "MetricMobileSessionCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SALES_COMPLETION_RATE"] = "MetricSalesCompletionRate";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ALL_TIME_PRODUCT_REVENUE"] = "MetricAllTimeProductRevenue";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_IMPRESSION_RATE"] = "MetricProductImpressionRate";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_CLICK_THROUGH_RATE"] = "MetricProductClickThroughRate";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_LANDING_PAGE_SESSION_MACRO_CONVERSION_COUNT"] = "MetricLandingPageSessionMacroConversionCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_LANDING_PAGE_SESSION_MACRO_CONVERSION_RATE"] = "MetricLandingPageSessionMacroConversionRate";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_NEW_CAMPAIGN_LEAD_COUNT"] = "MetricNewCampaignLeadCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_CLS_SCORE"] = "MetricAverageCLSScore";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_INP_SCORE"] = "MetricAverageINPScore";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_LCP_SCORE"] = "MetricAverageLCPScore";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_FCP_SCORE"] = "MetricAverageFCPScore";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_CWV_PERFORMANCE_SCORE"] = "MetricAverageCWVPerformanceScore";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_BOUNCED_SESSION_COUNT"] = "MetricBouncedSessionCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_BOUNCE_RATE"] = "MetricBounceRate";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_EXIT_PAGEVIEW_COUNT"] = "MetricExitPageviewCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_EXIT_RATE"] = "MetricExitRate";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SPEND"] = "MetricSpend";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_CPA"] = "MetricCPA";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_CAC"] = "MetricCAC";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ECR"] = "MetricECR";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_CPNV"] = "MetricCPNV";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_REVENUE"] = "MetricAttributionFirstClickRevenue";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_REVENUE"] = "MetricAttributionLastClickRevenue";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_REVENUE"] = "MetricAttributionLinearRevenue";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_REVENUE"] = "MetricAttributionLastNonDirectClickRevenue";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_REVENUE"] = "MetricAttributionLastPositionClickRevenue";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_REVENUE"] = "MetricAttributionImpactedClickRevenue";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_SALES_COUNT"] = "MetricAttributionFirstClickSalesCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_SALES_COUNT"] = "MetricAttributionLastClickSalesCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_SALES_COUNT"] = "MetricAttributionLinearSalesCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_SALES_COUNT"] = "MetricAttributionLastNonDirectClickSalesCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_SALES_COUNT"] = "MetricAttributionLastPositionClickSalesCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_SALES_COUNT"] = "MetricAttributionImpactedClickSalesCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_ROAS"] = "MetricAttributionFirstClickROAS";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_ROAS"] = "MetricAttributionLastClickROAS";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_ROAS"] = "MetricAttributionLinearROAS";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROAS"] = "MetricAttributionLastNonDirectClickROAS";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROAS"] = "MetricAttributionLastPositionClickROAS";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROAS"] = "MetricAttributionImpactedClickROAS";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_LTV"] = "MetricAttributionFirstClickLTV";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_LTV"] = "MetricAttributionLastClickLTV";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_LTV"] = "MetricAttributionLinearLTV";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_LTV"] = "MetricAttributionLastNonDirectClickLTV";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_LTV"] = "MetricAttributionLastPositionClickLTV";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_LTV"] = "MetricAttributionImpactedClickLTV";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_ROASLTV"] = "MetricAttributionFirstClickROASLTV";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_ROASLTV"] = "MetricAttributionLastClickROASLTV";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_ROASLTV"] = "MetricAttributionLinearROASLTV";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROASLTV"] = "MetricAttributionLastNonDirectClickROASLTV";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROASLTV"] = "MetricAttributionLastPositionClickROASLTV";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROASLTV"] = "MetricAttributionImpactedClickROASLTV";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionFirstClickMacroConversionCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionLastClickMacroConversionCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_MACRO_CONVERSION_COUNT"] = "MetricAttributionLinearMacroConversionCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionLastNonDirectClickMacroConversionCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionLastPositionClickMacroConversionCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionImpactedClickMacroConversionCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_ALL_INCLUSIVE_COST"] = "MetricSessionAllInclusiveCost";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_CONTRIBUTION_MARGIN"] = "MetricSessionContributionMargin";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_EFFICIENCY_RATION"] = "MetricSessionEfficiencyRation";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_ROI"] = "MetricAttributionFirstClickROI";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_ROI"] = "MetricAttributionLastClickROI";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_ROI"] = "MetricAttributionLinearROI";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROI"] = "MetricAttributionLastNonDirectClickROI";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROI"] = "MetricAttributionLastPositionClickROI";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROI"] = "MetricAttributionImpactedClickROI";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_ROILTV"] = "MetricAttributionFirstClickROILTV";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_ROILTV"] = "MetricAttributionLastClickROILTV";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_ROILTV"] = "MetricAttributionLinearROILTV";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROILTV"] = "MetricAttributionLastNonDirectClickROILTV";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROILTV"] = "MetricAttributionLastPositionClickROILTV";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROILTV"] = "MetricAttributionImpactedClickROILTV";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_LTVCAC_RATIO"] = "MetricLTVCACRatio";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_EXISTING_CUSTOMER_REVENUE_GROWTH_RATE"] = "MetricExistingCustomerRevenueGrowthRate";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_REVENUE"] = "MetricAttributionFirstNonDirectClickRevenue";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_SALES_COUNT"] = "MetricAttributionFirstNonDirectClickSalesCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROAS"] = "MetricAttributionFirstNonDirectClickROAS";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_LTV"] = "MetricAttributionFirstNonDirectClickLTV";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROASLTV"] = "MetricAttributionFirstNonDirectClickROASLTV";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionFirstNonDirectClickMacroConversionCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROI"] = "MetricAttributionFirstNonDirectClickROI";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROILTV"] = "MetricAttributionFirstNonDirectClickROILTV";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_RANK"] = "MetricRank";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_GATEWAY_LTV"] = "MetricProductGatewayLTV";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_PROFIT"] = "MetricAttributionFirstClickProfit";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_PROFIT"] = "MetricAttributionLastClickProfit";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_PROFIT"] = "MetricAttributionLinearProfit";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_PROFIT"] = "MetricAttributionLastNonDirectClickProfit";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_PROFIT"] = "MetricAttributionLastPositionClickProfit";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_PROFIT"] = "MetricAttributionImpactedClickProfit";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_PROFIT"] = "MetricAttributionFirstNonDirectClickProfit";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_LTV_PROFIT"] = "MetricAttributionFirstClickLTVProfit";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_LTV_PROFIT"] = "MetricAttributionLastClickLTVProfit";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_LTV_PROFIT"] = "MetricAttributionLinearLTVProfit";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_LTV_PROFIT"] = "MetricAttributionLastNonDirectClickLTVProfit";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_LTV_PROFIT"] = "MetricAttributionLastPositionClickLTVProfit";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_LTV_PROFIT"] = "MetricAttributionImpactedClickLTVProfit";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_LTV_PROFIT"] = "MetricAttributionFirstNonDirectClickLTVProfit";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_PROFIT"] = "MetricSessionProfit";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_JOB_IMPRESSION_COUNT"] = "MetricJobImpressionCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_JOB_IMPRESSION_RATE"] = "MetricJobImpressionRate";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_VISIT_TO_APPLICATION_RATE"] = "MetricVisitToApplicationRate";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_JOB_APPLY_CLICK_RATE"] = "MetricPageJobApplyClickRate";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_JOB_APPLICATIONS_RATE"] = "MetricPageJobApplicationsRate";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_JOB_CLICK_THROUGH_COUNT"] = "MetricJobClickThroughCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_JOB_CLICK_THROUGH_RATE"] = "MetricJobClickThroughRate";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ACTIVE_LEADS_LAST_SEVEN_DAYS"] = "MetricActiveLeadsLastSevenDays";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ACTIVE_LEADS_LAST_THIRTY_DAYS"] = "MetricActiveLeadsLastThirtyDays";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_LEAD_SCORE"] = "MetricAverageLeadScore";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_LEADS_COUNT_WITH_LEADS_SCORE"] = "MetricLeadsCountWithLeadsScore";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_RATE"] = "MetricAttributionImpactedClickMacroConversionRate";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PUBLISHER_CLICKS"] = "MetricPublisherClicks";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PUBLISHER_CONVERSIONS"] = "MetricPublisherConversions";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PUBLISHER_ALL_CONVERSIONS"] = "MetricPublisherAllConversions";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PUBLISHER_CPC"] = "MetricPublisherCPC";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_TO_NEW_CUSTOMER_RATE"] = "MetricToNewCustomerRate";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_CUSTOMER_SESSION_COUNT"] = "MetricReturningCustomerSessionCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_CUSTOMER_PAGE_MACRO_CONVERSION_COUNT"] = "MetricReturningCustomerPageMacroConversionCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_CUSTOMER_SESSION_MACRO_CONVERSION_COUNT"] = "MetricReturningCustomerSessionMacroConversionCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_CUSTOMER_SESSION_MACRO_CONVERSION_RATE"] = "MetricReturningCustomerSessionMacroConversionRate";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_CUSTOMER_RATE"] = "MetricReturningCustomerRate";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PUBLISHER_IMPRESSIONS"] = "MetricPublisherImpressions";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PUBLISHER_CTR"] = "MetricPublisherCTR";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_CHECKOUT_START_COUNT"] = "MetricCheckoutStartCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_CHECKOUT_START_RATE"] = "MetricCheckoutStartRate";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_CHECKOUT_COMPLETION_RATE"] = "MetricCheckoutCompletionRate";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PAID_SESSION_COUNT"] = "MetricPaidSessionCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ORGANIC_SESSION_COUNT"] = "MetricOrganicSessionCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_PAGE_EFFECTIVENESS_RATE"] = "MetricProductPageEffectivenessRate";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_CART_ABANDONMENT_RATE"] = "MetricCartAbandonmentRate";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_CHECKOUT_ABANDONMENT_RATE"] = "MetricCheckoutAbandonmentRate";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_REPEAT_CUSTOMER_COUNT"] = "MetricRepeatCustomerCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_REPEAT_CUSTOMER_RATE"] = "MetricRepeatCustomerRate";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_REPEAT_CUSTOMER_REVENUE"] = "MetricRepeatCustomerRevenue";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_REPEAT_CUSTOMER_SALES_COUNT"] = "MetricRepeatCustomerSalesCount";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_CALCULATED_CTR"] = "MetricCalculatedCTR";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SALES_COUNT"] = "MetricSalesCount";
})(ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum || (ChartServiceDeleteChartPayloadDisplayOptionsAvailableMetricsEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum;
(function (ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum) {
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_NOT_SET"] = "DimensionNotSet";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_BY_DAY"] = "DimensionSessionStartByDay";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LOCATION"] = "DimensionLocation";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_BROWSER"] = "DimensionBrowser";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_OS"] = "DimensionOS";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_DEVICE"] = "DimensionDevice";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_CHANNEL"] = "DimensionChannel";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_USER_TYPE"] = "DimensionUserType";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_COUNTRY"] = "DimensionCountry";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_REGION"] = "DimensionRegion";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_CITY"] = "DimensionCity";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LON_LAT"] = "DimensionLonLat";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_AUDIENCE"] = "DimensionSessionAudience";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LEAD_AUDIENCE"] = "DimensionLeadAudience";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_SOURCE"] = "DimensionSource";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_MEDIUM"] = "DimensionMedium";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_EXTERNAL_CAMPAIGN"] = "DimensionExternalCampaign";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_CONTENT"] = "DimensionContent";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_TERM"] = "DimensionTerm";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_PATH"] = "DimensionPath";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_PRODUCT_NAME"] = "DimensionProductName";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_PRODUCT_CATEGORY"] = "DimensionProductCategory";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_PRODUCT_BRAND"] = "DimensionProductBrand";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_JOB_TITLE"] = "DimensionJobTitle";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_JOB_CATEGORY"] = "DimensionJobCategory";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_PRODUCT"] = "DimensionProduct";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_JOB"] = "DimensionJob";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LOCATION_PAGE_TYPE"] = "DimensionLocationPageType";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_PATH"] = "DimensionLandingPagePath";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingPageLocationTaggerValue";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_LOCATION"] = "DimensionLandingPageLocation";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_TYPE"] = "DimensionLandingPageType";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LOCATION_TAGGER_VALUE"] = "DimensionLocationTaggerValue";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_NEXT_PAGE_PATH"] = "DimensionLandingNextPagePath";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingNextPageLocationTaggerValue";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION"] = "DimensionLandingNextPageLocation";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_NEXT_PAGE_TYPE"] = "DimensionLandingNextPageType";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_BY_HOUR"] = "DimensionSessionStartByHour";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_BY_WEEK"] = "DimensionSessionStartByWeek";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_BY_MONTH"] = "DimensionSessionStartByMonth";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_BY_WEEKDAY"] = "DimensionSessionStartByWeekday";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_MICRO_CONVERSION"] = "DimensionMicroConversion";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_MACRO_CONVERSION"] = "DimensionMacroConversion";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_CRAWLER"] = "DimensionCrawler";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_DAYS_TO_SECOND_VISIT"] = "DimensionDaysToSecondVisit";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_JOURNEY"] = "DimensionSessionJourney";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LEAD_STATUS"] = "DimensionLeadStatus";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LOCATION_TAGGER"] = "DimensionLocationTagger";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_SOURCE_BUCKET"] = "DimensionSourceBucket";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_LANDING_PAGE_LOCATION"] = "DimensionSessionLandingPageLocation";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_BROWSER_VERSION"] = "DimensionBrowserVersion";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_BROWSER_WITH_VERSION"] = "DimensionBrowserWithVersion";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_CHANNEL_MACRO_CONVERSION_JOURNEY"] = "DimensionChannelMacroConversionJourney";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_CONVERTING_SESSION_JOURNEY"] = "DimensionConvertingSessionJourney";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_VISITS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionVisitsSincePriorMacroConversion";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_DAYS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionDaysSincePriorMacroConversion";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_CHANNEL_SALES_JOURNEY"] = "DimensionChannelSalesJourney";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_SALES_SESSION_JOURNEY"] = "DimensionSalesSessionJourney";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER"] = "DimensionLandingPageLocationTagger";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER"] = "DimensionLandingNextPageLocationTagger";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_LANDING_PAGE_PATH"] = "DimensionSessionLandingPagePath";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_CAMPAIGN"] = "DimensionCampaign";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_CAMPAIGN_LEAD_STATUS"] = "DimensionCampaignLeadStatus";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_CAMPAIGN_TYPE"] = "DimensionCampaignType";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_AB_CAMPAIGN"] = "DimensionABCampaign";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_DOMAIN"] = "DimensionDomain";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_PERSONALISATION"] = "DimensionPersonalisation";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_VISITS_TO_FIRST_MACRO_CONVERSION"] = "DimensionVisitsToFirstMacroConversion";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_DAYS_TO_FIRST_MACRO_CONVERSION"] = "DimensionDaysToFirstMacroConversion";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_IS_RETURNING_CUSTOMER"] = "DimensionIsReturningCustomer";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_ID"] = "DimensionSessionId";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_DATE"] = "DimensionSessionStartDate";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_TRANSACTION_ID"] = "DimensionTransactionId";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_QS_PARAM"] = "DimensionQSParam";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_QS_VALUE"] = "DimensionQSValue";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_QS_PAIR"] = "DimensionQSPair";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_QS"] = "DimensionQS";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_FULL_URL"] = "DimensionFullURL";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_QS_PARAM"] = "DimensionLandingPageQSParam";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_QS_VALUE"] = "DimensionLandingPageQSValue";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_QS_PAIR"] = "DimensionLandingPageQSPair";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_QS"] = "DimensionLandingPageQS";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_FULL_URL"] = "DimensionLandingPageFullURL";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_REFERRER"] = "DimensionReferrer";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_REFERRER_PATH"] = "DimensionReferrerPath";
    ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_REFERRER_DOMAIN"] = "DimensionReferrerDomain";
})(ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum || (ChartServiceDeleteChartPayloadDisplayOptionsAvailableDimensionsEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum;
(function (ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum) {
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_NOT_SET"] = "DimensionNotSet";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_BY_DAY"] = "DimensionSessionStartByDay";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LOCATION"] = "DimensionLocation";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_BROWSER"] = "DimensionBrowser";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_OS"] = "DimensionOS";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_DEVICE"] = "DimensionDevice";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_CHANNEL"] = "DimensionChannel";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_USER_TYPE"] = "DimensionUserType";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_COUNTRY"] = "DimensionCountry";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_REGION"] = "DimensionRegion";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_CITY"] = "DimensionCity";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LON_LAT"] = "DimensionLonLat";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_AUDIENCE"] = "DimensionSessionAudience";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LEAD_AUDIENCE"] = "DimensionLeadAudience";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_SOURCE"] = "DimensionSource";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_MEDIUM"] = "DimensionMedium";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_EXTERNAL_CAMPAIGN"] = "DimensionExternalCampaign";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_CONTENT"] = "DimensionContent";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_TERM"] = "DimensionTerm";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_PATH"] = "DimensionPath";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_PRODUCT_NAME"] = "DimensionProductName";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_PRODUCT_CATEGORY"] = "DimensionProductCategory";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_PRODUCT_BRAND"] = "DimensionProductBrand";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_JOB_TITLE"] = "DimensionJobTitle";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_JOB_CATEGORY"] = "DimensionJobCategory";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_PRODUCT"] = "DimensionProduct";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_JOB"] = "DimensionJob";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LOCATION_PAGE_TYPE"] = "DimensionLocationPageType";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_PATH"] = "DimensionLandingPagePath";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingPageLocationTaggerValue";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_LOCATION"] = "DimensionLandingPageLocation";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_TYPE"] = "DimensionLandingPageType";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LOCATION_TAGGER_VALUE"] = "DimensionLocationTaggerValue";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_NEXT_PAGE_PATH"] = "DimensionLandingNextPagePath";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingNextPageLocationTaggerValue";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION"] = "DimensionLandingNextPageLocation";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_NEXT_PAGE_TYPE"] = "DimensionLandingNextPageType";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_BY_HOUR"] = "DimensionSessionStartByHour";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_BY_WEEK"] = "DimensionSessionStartByWeek";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_BY_MONTH"] = "DimensionSessionStartByMonth";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_BY_WEEKDAY"] = "DimensionSessionStartByWeekday";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_MICRO_CONVERSION"] = "DimensionMicroConversion";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_MACRO_CONVERSION"] = "DimensionMacroConversion";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_CRAWLER"] = "DimensionCrawler";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_DAYS_TO_SECOND_VISIT"] = "DimensionDaysToSecondVisit";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_JOURNEY"] = "DimensionSessionJourney";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LEAD_STATUS"] = "DimensionLeadStatus";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LOCATION_TAGGER"] = "DimensionLocationTagger";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_SOURCE_BUCKET"] = "DimensionSourceBucket";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_LANDING_PAGE_LOCATION"] = "DimensionSessionLandingPageLocation";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_BROWSER_VERSION"] = "DimensionBrowserVersion";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_BROWSER_WITH_VERSION"] = "DimensionBrowserWithVersion";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_CHANNEL_MACRO_CONVERSION_JOURNEY"] = "DimensionChannelMacroConversionJourney";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_CONVERTING_SESSION_JOURNEY"] = "DimensionConvertingSessionJourney";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_VISITS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionVisitsSincePriorMacroConversion";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_DAYS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionDaysSincePriorMacroConversion";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_CHANNEL_SALES_JOURNEY"] = "DimensionChannelSalesJourney";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_SALES_SESSION_JOURNEY"] = "DimensionSalesSessionJourney";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER"] = "DimensionLandingPageLocationTagger";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER"] = "DimensionLandingNextPageLocationTagger";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_LANDING_PAGE_PATH"] = "DimensionSessionLandingPagePath";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_CAMPAIGN"] = "DimensionCampaign";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_CAMPAIGN_LEAD_STATUS"] = "DimensionCampaignLeadStatus";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_CAMPAIGN_TYPE"] = "DimensionCampaignType";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_AB_CAMPAIGN"] = "DimensionABCampaign";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_DOMAIN"] = "DimensionDomain";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_PERSONALISATION"] = "DimensionPersonalisation";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_VISITS_TO_FIRST_MACRO_CONVERSION"] = "DimensionVisitsToFirstMacroConversion";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_DAYS_TO_FIRST_MACRO_CONVERSION"] = "DimensionDaysToFirstMacroConversion";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_IS_RETURNING_CUSTOMER"] = "DimensionIsReturningCustomer";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_ID"] = "DimensionSessionId";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_DATE"] = "DimensionSessionStartDate";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_TRANSACTION_ID"] = "DimensionTransactionId";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_QS_PARAM"] = "DimensionQSParam";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_QS_VALUE"] = "DimensionQSValue";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_QS_PAIR"] = "DimensionQSPair";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_QS"] = "DimensionQS";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_FULL_URL"] = "DimensionFullURL";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_QS_PARAM"] = "DimensionLandingPageQSParam";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_QS_VALUE"] = "DimensionLandingPageQSValue";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_QS_PAIR"] = "DimensionLandingPageQSPair";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_QS"] = "DimensionLandingPageQS";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_FULL_URL"] = "DimensionLandingPageFullURL";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_REFERRER"] = "DimensionReferrer";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_REFERRER_PATH"] = "DimensionReferrerPath";
    ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_REFERRER_DOMAIN"] = "DimensionReferrerDomain";
})(ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum || (ChartServiceDeleteChartPayloadDisplayOptionsFilterDropdownsEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ChartServiceDeleteChartPayloadDisplayOptionsLayoutEnum;
(function (ChartServiceDeleteChartPayloadDisplayOptionsLayoutEnum) {
    ChartServiceDeleteChartPayloadDisplayOptionsLayoutEnum["HORIZONTAL"] = "horizontal";
    ChartServiceDeleteChartPayloadDisplayOptionsLayoutEnum["VERTICAL"] = "vertical";
    ChartServiceDeleteChartPayloadDisplayOptionsLayoutEnum["CENTRIC"] = "centric";
    ChartServiceDeleteChartPayloadDisplayOptionsLayoutEnum["RADIAL"] = "radial";
})(ChartServiceDeleteChartPayloadDisplayOptionsLayoutEnum || (ChartServiceDeleteChartPayloadDisplayOptionsLayoutEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum;
(function (ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum) {
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_NOT_SET"] = "DimensionNotSet";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_BY_DAY"] = "DimensionSessionStartByDay";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LOCATION"] = "DimensionLocation";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_BROWSER"] = "DimensionBrowser";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_OS"] = "DimensionOS";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_DEVICE"] = "DimensionDevice";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_CHANNEL"] = "DimensionChannel";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_USER_TYPE"] = "DimensionUserType";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_COUNTRY"] = "DimensionCountry";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_REGION"] = "DimensionRegion";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_CITY"] = "DimensionCity";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LON_LAT"] = "DimensionLonLat";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_AUDIENCE"] = "DimensionSessionAudience";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LEAD_AUDIENCE"] = "DimensionLeadAudience";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_SOURCE"] = "DimensionSource";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_MEDIUM"] = "DimensionMedium";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_EXTERNAL_CAMPAIGN"] = "DimensionExternalCampaign";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_CONTENT"] = "DimensionContent";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_TERM"] = "DimensionTerm";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_PATH"] = "DimensionPath";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_PRODUCT_NAME"] = "DimensionProductName";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_PRODUCT_CATEGORY"] = "DimensionProductCategory";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_PRODUCT_BRAND"] = "DimensionProductBrand";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_JOB_TITLE"] = "DimensionJobTitle";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_JOB_CATEGORY"] = "DimensionJobCategory";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_PRODUCT"] = "DimensionProduct";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_JOB"] = "DimensionJob";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LOCATION_PAGE_TYPE"] = "DimensionLocationPageType";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_PATH"] = "DimensionLandingPagePath";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingPageLocationTaggerValue";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_LOCATION"] = "DimensionLandingPageLocation";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_TYPE"] = "DimensionLandingPageType";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LOCATION_TAGGER_VALUE"] = "DimensionLocationTaggerValue";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_NEXT_PAGE_PATH"] = "DimensionLandingNextPagePath";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingNextPageLocationTaggerValue";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION"] = "DimensionLandingNextPageLocation";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_NEXT_PAGE_TYPE"] = "DimensionLandingNextPageType";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_BY_HOUR"] = "DimensionSessionStartByHour";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_BY_WEEK"] = "DimensionSessionStartByWeek";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_BY_MONTH"] = "DimensionSessionStartByMonth";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_BY_WEEKDAY"] = "DimensionSessionStartByWeekday";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_MICRO_CONVERSION"] = "DimensionMicroConversion";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_MACRO_CONVERSION"] = "DimensionMacroConversion";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_CRAWLER"] = "DimensionCrawler";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_DAYS_TO_SECOND_VISIT"] = "DimensionDaysToSecondVisit";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_JOURNEY"] = "DimensionSessionJourney";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LEAD_STATUS"] = "DimensionLeadStatus";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LOCATION_TAGGER"] = "DimensionLocationTagger";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_SOURCE_BUCKET"] = "DimensionSourceBucket";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_LANDING_PAGE_LOCATION"] = "DimensionSessionLandingPageLocation";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_BROWSER_VERSION"] = "DimensionBrowserVersion";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_BROWSER_WITH_VERSION"] = "DimensionBrowserWithVersion";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_CHANNEL_MACRO_CONVERSION_JOURNEY"] = "DimensionChannelMacroConversionJourney";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_CONVERTING_SESSION_JOURNEY"] = "DimensionConvertingSessionJourney";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_VISITS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionVisitsSincePriorMacroConversion";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_DAYS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionDaysSincePriorMacroConversion";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_CHANNEL_SALES_JOURNEY"] = "DimensionChannelSalesJourney";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_SALES_SESSION_JOURNEY"] = "DimensionSalesSessionJourney";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER"] = "DimensionLandingPageLocationTagger";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER"] = "DimensionLandingNextPageLocationTagger";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_LANDING_PAGE_PATH"] = "DimensionSessionLandingPagePath";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_CAMPAIGN"] = "DimensionCampaign";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_CAMPAIGN_LEAD_STATUS"] = "DimensionCampaignLeadStatus";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_CAMPAIGN_TYPE"] = "DimensionCampaignType";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_AB_CAMPAIGN"] = "DimensionABCampaign";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_DOMAIN"] = "DimensionDomain";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_PERSONALISATION"] = "DimensionPersonalisation";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_VISITS_TO_FIRST_MACRO_CONVERSION"] = "DimensionVisitsToFirstMacroConversion";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_DAYS_TO_FIRST_MACRO_CONVERSION"] = "DimensionDaysToFirstMacroConversion";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_IS_RETURNING_CUSTOMER"] = "DimensionIsReturningCustomer";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_ID"] = "DimensionSessionId";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_DATE"] = "DimensionSessionStartDate";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_TRANSACTION_ID"] = "DimensionTransactionId";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_QS_PARAM"] = "DimensionQSParam";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_QS_VALUE"] = "DimensionQSValue";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_QS_PAIR"] = "DimensionQSPair";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_QS"] = "DimensionQS";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_FULL_URL"] = "DimensionFullURL";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_QS_PARAM"] = "DimensionLandingPageQSParam";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_QS_VALUE"] = "DimensionLandingPageQSValue";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_QS_PAIR"] = "DimensionLandingPageQSPair";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_QS"] = "DimensionLandingPageQS";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_FULL_URL"] = "DimensionLandingPageFullURL";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_REFERRER"] = "DimensionReferrer";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_REFERRER_PATH"] = "DimensionReferrerPath";
    ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_REFERRER_DOMAIN"] = "DimensionReferrerDomain";
})(ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum || (ChartServiceDeleteChartPayloadDisplayOptionsFullAttributesListEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ChartServiceDeleteChartPayloadCrawlerEnum;
(function (ChartServiceDeleteChartPayloadCrawlerEnum) {
    ChartServiceDeleteChartPayloadCrawlerEnum["NO_CRAWLERS"] = "NO_CRAWLERS";
    ChartServiceDeleteChartPayloadCrawlerEnum["ONLY_CRAWLERS"] = "ONLY_CRAWLERS";
    ChartServiceDeleteChartPayloadCrawlerEnum["WITH_CRAWLERS"] = "WITH_CRAWLERS";
})(ChartServiceDeleteChartPayloadCrawlerEnum || (ChartServiceDeleteChartPayloadCrawlerEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ChartServiceReadChartPayloadTypeEnum;
(function (ChartServiceReadChartPayloadTypeEnum) {
    ChartServiceReadChartPayloadTypeEnum["UNDEFINED"] = "CHART_TYPE_UNDEFINED";
    ChartServiceReadChartPayloadTypeEnum["PIE"] = "CHART_TYPE_PIE";
    ChartServiceReadChartPayloadTypeEnum["GRAPH"] = "CHART_TYPE_GRAPH";
    ChartServiceReadChartPayloadTypeEnum["RADAR"] = "CHART_TYPE_RADAR";
    ChartServiceReadChartPayloadTypeEnum["TABLE"] = "CHART_TYPE_TABLE";
    ChartServiceReadChartPayloadTypeEnum["BUBBLE"] = "CHART_TYPE_BUBBLE";
    ChartServiceReadChartPayloadTypeEnum["TREE_MAP"] = "CHART_TYPE_TREE_MAP";
    ChartServiceReadChartPayloadTypeEnum["STAT_CARD"] = "CHART_TYPE_STAT_CARD";
    ChartServiceReadChartPayloadTypeEnum["FUNNEL"] = "CHART_TYPE_FUNNEL";
})(ChartServiceReadChartPayloadTypeEnum || (ChartServiceReadChartPayloadTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ChartServiceReadChartPayloadTemplateEnum;
(function (ChartServiceReadChartPayloadTemplateEnum) {
    ChartServiceReadChartPayloadTemplateEnum["UNDEFINED"] = "CHART_TEMPLATE_UNDEFINED";
    ChartServiceReadChartPayloadTemplateEnum["TREND"] = "CHART_TEMPLATE_TREND";
    ChartServiceReadChartPayloadTemplateEnum["MAP"] = "CHART_TEMPLATE_MAP";
    ChartServiceReadChartPayloadTemplateEnum["SNAPSHOT"] = "CHART_TEMPLATE_SNAPSHOT";
    ChartServiceReadChartPayloadTemplateEnum["TABLE"] = "CHART_TEMPLATE_TABLE";
    ChartServiceReadChartPayloadTemplateEnum["ATTRIBUTION"] = "CHART_TEMPLATE_ATTRIBUTION";
})(ChartServiceReadChartPayloadTemplateEnum || (ChartServiceReadChartPayloadTemplateEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum;
(function (ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum) {
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_NOT_SET"] = "MetricNotSet";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_VIEWS_COUNT"] = "MetricPageViewsCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SESSIONS_COUNT"] = "MetricSessionsCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_LEADS_COUNT"] = "MetricLeadsCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_NEW_LEADS_COUNT"] = "MetricNewLeadsCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_LEADS_COUNT"] = "MetricReturningLeadsCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_REVENUE"] = "MetricSessionRevenue";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_COUNT_PER_LEAD"] = "MetricSessionCountPerLead";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_NEW_SESSION_COUNT"] = "MetricNewSessionCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_SESSION_COUNT"] = "MetricReturningSessionCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_PAGEVIEW_COUNT"] = "MetricSessionPageviewCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_PAGEVIEWS_PER_SESSION"] = "MetricAveragePageviewsPerSession";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_SESSION_DURATION"] = "MetricAverageSessionDuration";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_SESSION_COUNT"] = "MetricEffectiveSessionCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_SESSION_COUNT_PER_LEAD"] = "MetricEffectiveSessionCountPerLead";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_SESSION_RATE"] = "MetricEffectiveSessionRate";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_PAGEVIEW_COUNT"] = "MetricEffectivePageviewCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_PAGEVIEW_COUNT_PER_LEAD"] = "MetricEffectivePageviewCountPerLead";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_PAGEVIEW_RATE"] = "MetricEffectivePageviewRate";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_TIME_ON_PAGE"] = "MetricAverageTimeOnPage";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_VIEWED_PERCENTAGE"] = "MetricAverageViewedPercentage";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_SCROLL_PERCENTAGE"] = "MetricAverageScrollPercentage";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_SCROLL_SPEED"] = "MetricAverageScrollSpeed";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_LEAD_PERCENTAGE"] = "MetricReturningLeadPercentage";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_SESSION_TO_CONVERSION_RATE"] = "MetricEffectiveSessionToConversionRate";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_MACRO_CONVERSION_COUNT"] = "MetricSessionMacroConversionCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_MICRO_CONVERSION_COUNT"] = "MetricSessionMicroConversionCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_MACRO_CONVERSION_RATE"] = "MetricSessionMacroConversionRate";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_MICRO_CONVERSION_RATE"] = "MetricSessionMicroConversionRate";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRICMACRO_CONVERSION_COUNT_PER_LEAD"] = "MetricmacroConversionCountPerLead";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRICMICRO_CONVERSION_COUNT_PER_LEAD"] = "MetricmicroConversionCountPerLead";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRICEFFECTIVE_PAGEVIEW_TO_CONVERSION_RATE"] = "MetriceffectivePageviewToConversionRate";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_MACRO_CONVERSION_COUNT"] = "MetricPageMacroConversionCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_MICRO_CONVERSION_COUNT"] = "MetricPageMicroConversionCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_MACRO_CONVERSION_RATE"] = "MetricPageMacroConversionRate";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_MICRO_CONVERSION_RATE"] = "MetricPageMicroConversionRate";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_LEAD_MACRO_CONVERSION_PERCENTAGE"] = "MetricLeadMacroConversionPercentage";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_EFFECTIVE_PAGEVIEW_COUNT_DURING_SESSION"] = "MetricAverageEffectivePageviewCountDuringSession";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_EFFECTIVE_PAGEVIEW_RATE_DURING_SESSION"] = "MetricAverageEffectivePageviewRateDuringSession";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_TIME_ON_PAGE_DURING_SESSION"] = "MetricAverageTimeOnPageDuringSession";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_VIEWED_PERCENTAGE_DURING_SESSION"] = "MetricAverageViewedPercentageDuringSession";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_SCROLL_PERCENTAGE_DURING_SESSION"] = "MetricAverageScrollPercentageDuringSession";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_SCROLL_SPEED_DURING_SESSION"] = "MetricAverageScrollSpeedDuringSession";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_NEW_CUSTOMER_COUNT"] = "MetricNewCustomerCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_SALES_COUNT"] = "MetricSessionSalesCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SALES_REVENUE"] = "MetricSalesRevenue";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_VISIT_TO_PURCHASE_RATE"] = "MetricVisitToPurchaseRate";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_SALE_VALUE"] = "MetricAverageSaleValue";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_FIRST_VISIT_SESSION_MACRO_CONVERSION_COUNT"] = "MetricFirstVisitSessionMacroConversionCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_THIRTY_DAY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricThirtyDaySessionMacroConversionCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SIXTY_DAY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricSixtyDaySessionMacroConversionCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_NINETY_DAY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricNinetyDaySessionMacroConversionCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_THIRTY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricThirtyDaySessionMacroConversionCountAdditional";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SIXTY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricSixtyDaySessionMacroConversionCountAdditional";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_NINETY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricNinetyDaySessionMacroConversionCountAdditional";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_FIRST_VISIT_SESSION_SALES_COUNT"] = "MetricFirstVisitSessionSalesCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_THIRTY_DAY_SESSION_SALES_COUNT"] = "MetricThirtyDaySessionSalesCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SIXTY_DAY_SESSION_SALES_COUNT"] = "MetricSixtyDaySessionSalesCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_NINETY_DAY_SESSION_SALES_COUNT"] = "MetricNinetyDaySessionSalesCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_THIRTY_DAY_SESSION_SALES_COUNT_ADDITIONAL"] = "MetricThirtyDaySessionSalesCountAdditional";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SIXTY_DAY_SESSION_SALES_COUNT_ADDITIONAL"] = "MetricSixtyDaySessionSalesCountAdditional";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_NINETY_DAY_SESSION_SALES_COUNT_ADDITIONAL"] = "MetricNinetyDaySessionSalesCountAdditional";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_FIRST_VISIT_PAGE_MACRO_CONVERSION_COUNT"] = "MetricFirstVisitPageMacroConversionCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRI_THIRTY_DAY_MACRO_CONVERSION_COUNT"] = "MetriThirtyDayMacroConversionCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SIXTY_DAY_MACRO_CONVERSION_COUNT"] = "MetricSixtyDayMacroConversionCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_NINETY_DAY_MACRO_CONVERSION_COUNT"] = "MetricNinetyDayMacroConversionCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_THIRTY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricThirtyDayMacroConversionCountAdditional";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SIXTY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricSixtyDayMacroConversionCountAdditional";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_NINETY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricNinetyDayMacroConversionCountAdditional";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_REVENUE"] = "MetricProductRevenue";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_NINETY_DAY_CUSTOMER_COUNT"] = "MetricReturningNinetyDayCustomerCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_NINETY_DAY_CUSTOMER_PERCENTAGE"] = "MetricReturningNinetyDayCustomerPercentage";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_PAGEVIEW_COUNT"] = "MetricProductPageviewCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_JOB_PAGEVIEW_COUNT"] = "MetricJobPageviewCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_LANDING_SESSION_COUNT"] = "MetricLandingSessionCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_CRAWLER_VISIT_COUNT"] = "MetricCrawlerVisitCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_OUT_OF_STOCK_PAGEVIEW_COUNT"] = "MetricOutOfStockPageviewCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_OUT_OF_STOCK_PAGES_COUNT"] = "MetricOutOfStockPagesCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_DAYS_TO_SECOND_VISIT"] = "MetricAverageDaysToSecondVisit";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_JOB_APPLICATIONS_COUNT"] = "MetricSessionJobApplicationsCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_PAGEVIEW_RATE_RANK"] = "MetricEffectivePageviewRateRank";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_JOB_APPLY_CLICK_COUNT"] = "MetricSessionJobApplyClickCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_ADD_TO_CART_CLICK_COUNT"] = "MetricSessionAddToCartClickCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_ADD_TO_CART_CLICK_RATE"] = "MetricSessionAddToCartClickRate";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_JOB_APPLICATIONS_COUNT"] = "MetricPageJobApplicationsCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_JOB_APPLY_CLICK_COUNT"] = "MetricPageJobApplyClickCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_ADD_TO_CART_CLICK_COUNT"] = "MetricPageAddToCartClickCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_ADD_TO_CART_CLICK_RATE"] = "MetricPageAddToCartClickRate";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_CLICK_THROUGH_COUNT"] = "MetricProductClickThroughCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_IMPRESSION_COUNT"] = "MetricProductImpressionCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_CUSTOMER_COUNT"] = "MetricReturningCustomerCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_DESKTOP_SESSION_COUNT"] = "MetricDesktopSessionCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_MOBILE_SESSION_COUNT"] = "MetricMobileSessionCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SALES_COMPLETION_RATE"] = "MetricSalesCompletionRate";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ALL_TIME_PRODUCT_REVENUE"] = "MetricAllTimeProductRevenue";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_IMPRESSION_RATE"] = "MetricProductImpressionRate";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_CLICK_THROUGH_RATE"] = "MetricProductClickThroughRate";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_LANDING_PAGE_SESSION_MACRO_CONVERSION_COUNT"] = "MetricLandingPageSessionMacroConversionCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_LANDING_PAGE_SESSION_MACRO_CONVERSION_RATE"] = "MetricLandingPageSessionMacroConversionRate";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_NEW_CAMPAIGN_LEAD_COUNT"] = "MetricNewCampaignLeadCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_CLS_SCORE"] = "MetricAverageCLSScore";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_INP_SCORE"] = "MetricAverageINPScore";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_LCP_SCORE"] = "MetricAverageLCPScore";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_FCP_SCORE"] = "MetricAverageFCPScore";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_CWV_PERFORMANCE_SCORE"] = "MetricAverageCWVPerformanceScore";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_BOUNCED_SESSION_COUNT"] = "MetricBouncedSessionCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_BOUNCE_RATE"] = "MetricBounceRate";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_EXIT_PAGEVIEW_COUNT"] = "MetricExitPageviewCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_EXIT_RATE"] = "MetricExitRate";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SPEND"] = "MetricSpend";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_CPA"] = "MetricCPA";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_CAC"] = "MetricCAC";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ECR"] = "MetricECR";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_CPNV"] = "MetricCPNV";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_REVENUE"] = "MetricAttributionFirstClickRevenue";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_REVENUE"] = "MetricAttributionLastClickRevenue";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_REVENUE"] = "MetricAttributionLinearRevenue";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_REVENUE"] = "MetricAttributionLastNonDirectClickRevenue";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_REVENUE"] = "MetricAttributionLastPositionClickRevenue";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_REVENUE"] = "MetricAttributionImpactedClickRevenue";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_SALES_COUNT"] = "MetricAttributionFirstClickSalesCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_SALES_COUNT"] = "MetricAttributionLastClickSalesCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_SALES_COUNT"] = "MetricAttributionLinearSalesCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_SALES_COUNT"] = "MetricAttributionLastNonDirectClickSalesCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_SALES_COUNT"] = "MetricAttributionLastPositionClickSalesCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_SALES_COUNT"] = "MetricAttributionImpactedClickSalesCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_ROAS"] = "MetricAttributionFirstClickROAS";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_ROAS"] = "MetricAttributionLastClickROAS";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_ROAS"] = "MetricAttributionLinearROAS";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROAS"] = "MetricAttributionLastNonDirectClickROAS";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROAS"] = "MetricAttributionLastPositionClickROAS";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROAS"] = "MetricAttributionImpactedClickROAS";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_LTV"] = "MetricAttributionFirstClickLTV";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_LTV"] = "MetricAttributionLastClickLTV";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_LTV"] = "MetricAttributionLinearLTV";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_LTV"] = "MetricAttributionLastNonDirectClickLTV";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_LTV"] = "MetricAttributionLastPositionClickLTV";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_LTV"] = "MetricAttributionImpactedClickLTV";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_ROASLTV"] = "MetricAttributionFirstClickROASLTV";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_ROASLTV"] = "MetricAttributionLastClickROASLTV";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_ROASLTV"] = "MetricAttributionLinearROASLTV";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROASLTV"] = "MetricAttributionLastNonDirectClickROASLTV";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROASLTV"] = "MetricAttributionLastPositionClickROASLTV";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROASLTV"] = "MetricAttributionImpactedClickROASLTV";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionFirstClickMacroConversionCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionLastClickMacroConversionCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_MACRO_CONVERSION_COUNT"] = "MetricAttributionLinearMacroConversionCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionLastNonDirectClickMacroConversionCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionLastPositionClickMacroConversionCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionImpactedClickMacroConversionCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_ALL_INCLUSIVE_COST"] = "MetricSessionAllInclusiveCost";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_CONTRIBUTION_MARGIN"] = "MetricSessionContributionMargin";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_EFFICIENCY_RATION"] = "MetricSessionEfficiencyRation";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_ROI"] = "MetricAttributionFirstClickROI";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_ROI"] = "MetricAttributionLastClickROI";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_ROI"] = "MetricAttributionLinearROI";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROI"] = "MetricAttributionLastNonDirectClickROI";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROI"] = "MetricAttributionLastPositionClickROI";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROI"] = "MetricAttributionImpactedClickROI";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_ROILTV"] = "MetricAttributionFirstClickROILTV";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_ROILTV"] = "MetricAttributionLastClickROILTV";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_ROILTV"] = "MetricAttributionLinearROILTV";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROILTV"] = "MetricAttributionLastNonDirectClickROILTV";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROILTV"] = "MetricAttributionLastPositionClickROILTV";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROILTV"] = "MetricAttributionImpactedClickROILTV";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_LTVCAC_RATIO"] = "MetricLTVCACRatio";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_EXISTING_CUSTOMER_REVENUE_GROWTH_RATE"] = "MetricExistingCustomerRevenueGrowthRate";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_REVENUE"] = "MetricAttributionFirstNonDirectClickRevenue";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_SALES_COUNT"] = "MetricAttributionFirstNonDirectClickSalesCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROAS"] = "MetricAttributionFirstNonDirectClickROAS";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_LTV"] = "MetricAttributionFirstNonDirectClickLTV";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROASLTV"] = "MetricAttributionFirstNonDirectClickROASLTV";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionFirstNonDirectClickMacroConversionCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROI"] = "MetricAttributionFirstNonDirectClickROI";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROILTV"] = "MetricAttributionFirstNonDirectClickROILTV";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_RANK"] = "MetricRank";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_GATEWAY_LTV"] = "MetricProductGatewayLTV";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_PROFIT"] = "MetricAttributionFirstClickProfit";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_PROFIT"] = "MetricAttributionLastClickProfit";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_PROFIT"] = "MetricAttributionLinearProfit";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_PROFIT"] = "MetricAttributionLastNonDirectClickProfit";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_PROFIT"] = "MetricAttributionLastPositionClickProfit";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_PROFIT"] = "MetricAttributionImpactedClickProfit";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_PROFIT"] = "MetricAttributionFirstNonDirectClickProfit";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_LTV_PROFIT"] = "MetricAttributionFirstClickLTVProfit";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_LTV_PROFIT"] = "MetricAttributionLastClickLTVProfit";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_LTV_PROFIT"] = "MetricAttributionLinearLTVProfit";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_LTV_PROFIT"] = "MetricAttributionLastNonDirectClickLTVProfit";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_LTV_PROFIT"] = "MetricAttributionLastPositionClickLTVProfit";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_LTV_PROFIT"] = "MetricAttributionImpactedClickLTVProfit";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_LTV_PROFIT"] = "MetricAttributionFirstNonDirectClickLTVProfit";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_PROFIT"] = "MetricSessionProfit";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_JOB_IMPRESSION_COUNT"] = "MetricJobImpressionCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_JOB_IMPRESSION_RATE"] = "MetricJobImpressionRate";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_VISIT_TO_APPLICATION_RATE"] = "MetricVisitToApplicationRate";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_JOB_APPLY_CLICK_RATE"] = "MetricPageJobApplyClickRate";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_JOB_APPLICATIONS_RATE"] = "MetricPageJobApplicationsRate";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_JOB_CLICK_THROUGH_COUNT"] = "MetricJobClickThroughCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_JOB_CLICK_THROUGH_RATE"] = "MetricJobClickThroughRate";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ACTIVE_LEADS_LAST_SEVEN_DAYS"] = "MetricActiveLeadsLastSevenDays";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ACTIVE_LEADS_LAST_THIRTY_DAYS"] = "MetricActiveLeadsLastThirtyDays";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_LEAD_SCORE"] = "MetricAverageLeadScore";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_LEADS_COUNT_WITH_LEADS_SCORE"] = "MetricLeadsCountWithLeadsScore";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_RATE"] = "MetricAttributionImpactedClickMacroConversionRate";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PUBLISHER_CLICKS"] = "MetricPublisherClicks";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PUBLISHER_CONVERSIONS"] = "MetricPublisherConversions";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PUBLISHER_ALL_CONVERSIONS"] = "MetricPublisherAllConversions";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PUBLISHER_CPC"] = "MetricPublisherCPC";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_TO_NEW_CUSTOMER_RATE"] = "MetricToNewCustomerRate";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_CUSTOMER_SESSION_COUNT"] = "MetricReturningCustomerSessionCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_CUSTOMER_PAGE_MACRO_CONVERSION_COUNT"] = "MetricReturningCustomerPageMacroConversionCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_CUSTOMER_SESSION_MACRO_CONVERSION_COUNT"] = "MetricReturningCustomerSessionMacroConversionCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_CUSTOMER_SESSION_MACRO_CONVERSION_RATE"] = "MetricReturningCustomerSessionMacroConversionRate";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_CUSTOMER_RATE"] = "MetricReturningCustomerRate";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PUBLISHER_IMPRESSIONS"] = "MetricPublisherImpressions";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PUBLISHER_CTR"] = "MetricPublisherCTR";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_CHECKOUT_START_COUNT"] = "MetricCheckoutStartCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_CHECKOUT_START_RATE"] = "MetricCheckoutStartRate";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_CHECKOUT_COMPLETION_RATE"] = "MetricCheckoutCompletionRate";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PAID_SESSION_COUNT"] = "MetricPaidSessionCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_ORGANIC_SESSION_COUNT"] = "MetricOrganicSessionCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_PAGE_EFFECTIVENESS_RATE"] = "MetricProductPageEffectivenessRate";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_CART_ABANDONMENT_RATE"] = "MetricCartAbandonmentRate";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_CHECKOUT_ABANDONMENT_RATE"] = "MetricCheckoutAbandonmentRate";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_REPEAT_CUSTOMER_COUNT"] = "MetricRepeatCustomerCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_REPEAT_CUSTOMER_RATE"] = "MetricRepeatCustomerRate";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_REPEAT_CUSTOMER_REVENUE"] = "MetricRepeatCustomerRevenue";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_REPEAT_CUSTOMER_SALES_COUNT"] = "MetricRepeatCustomerSalesCount";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_CALCULATED_CTR"] = "MetricCalculatedCTR";
    ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum["METRIC_SALES_COUNT"] = "MetricSalesCount";
})(ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum || (ChartServiceReadChartPayloadDisplayOptionsAvailableMetricsEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum;
(function (ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum) {
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_NOT_SET"] = "DimensionNotSet";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_BY_DAY"] = "DimensionSessionStartByDay";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LOCATION"] = "DimensionLocation";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_BROWSER"] = "DimensionBrowser";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_OS"] = "DimensionOS";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_DEVICE"] = "DimensionDevice";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_CHANNEL"] = "DimensionChannel";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_USER_TYPE"] = "DimensionUserType";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_COUNTRY"] = "DimensionCountry";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_REGION"] = "DimensionRegion";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_CITY"] = "DimensionCity";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LON_LAT"] = "DimensionLonLat";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_AUDIENCE"] = "DimensionSessionAudience";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LEAD_AUDIENCE"] = "DimensionLeadAudience";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_SOURCE"] = "DimensionSource";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_MEDIUM"] = "DimensionMedium";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_EXTERNAL_CAMPAIGN"] = "DimensionExternalCampaign";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_CONTENT"] = "DimensionContent";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_TERM"] = "DimensionTerm";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_PATH"] = "DimensionPath";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_PRODUCT_NAME"] = "DimensionProductName";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_PRODUCT_CATEGORY"] = "DimensionProductCategory";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_PRODUCT_BRAND"] = "DimensionProductBrand";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_JOB_TITLE"] = "DimensionJobTitle";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_JOB_CATEGORY"] = "DimensionJobCategory";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_PRODUCT"] = "DimensionProduct";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_JOB"] = "DimensionJob";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LOCATION_PAGE_TYPE"] = "DimensionLocationPageType";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_PATH"] = "DimensionLandingPagePath";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingPageLocationTaggerValue";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_LOCATION"] = "DimensionLandingPageLocation";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_TYPE"] = "DimensionLandingPageType";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LOCATION_TAGGER_VALUE"] = "DimensionLocationTaggerValue";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_NEXT_PAGE_PATH"] = "DimensionLandingNextPagePath";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingNextPageLocationTaggerValue";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION"] = "DimensionLandingNextPageLocation";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_NEXT_PAGE_TYPE"] = "DimensionLandingNextPageType";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_BY_HOUR"] = "DimensionSessionStartByHour";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_BY_WEEK"] = "DimensionSessionStartByWeek";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_BY_MONTH"] = "DimensionSessionStartByMonth";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_BY_WEEKDAY"] = "DimensionSessionStartByWeekday";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_MICRO_CONVERSION"] = "DimensionMicroConversion";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_MACRO_CONVERSION"] = "DimensionMacroConversion";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_CRAWLER"] = "DimensionCrawler";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_DAYS_TO_SECOND_VISIT"] = "DimensionDaysToSecondVisit";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_JOURNEY"] = "DimensionSessionJourney";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LEAD_STATUS"] = "DimensionLeadStatus";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LOCATION_TAGGER"] = "DimensionLocationTagger";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_SOURCE_BUCKET"] = "DimensionSourceBucket";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_LANDING_PAGE_LOCATION"] = "DimensionSessionLandingPageLocation";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_BROWSER_VERSION"] = "DimensionBrowserVersion";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_BROWSER_WITH_VERSION"] = "DimensionBrowserWithVersion";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_CHANNEL_MACRO_CONVERSION_JOURNEY"] = "DimensionChannelMacroConversionJourney";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_CONVERTING_SESSION_JOURNEY"] = "DimensionConvertingSessionJourney";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_VISITS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionVisitsSincePriorMacroConversion";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_DAYS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionDaysSincePriorMacroConversion";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_CHANNEL_SALES_JOURNEY"] = "DimensionChannelSalesJourney";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_SALES_SESSION_JOURNEY"] = "DimensionSalesSessionJourney";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER"] = "DimensionLandingPageLocationTagger";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER"] = "DimensionLandingNextPageLocationTagger";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_LANDING_PAGE_PATH"] = "DimensionSessionLandingPagePath";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_CAMPAIGN"] = "DimensionCampaign";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_CAMPAIGN_LEAD_STATUS"] = "DimensionCampaignLeadStatus";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_CAMPAIGN_TYPE"] = "DimensionCampaignType";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_AB_CAMPAIGN"] = "DimensionABCampaign";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_DOMAIN"] = "DimensionDomain";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_PERSONALISATION"] = "DimensionPersonalisation";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_VISITS_TO_FIRST_MACRO_CONVERSION"] = "DimensionVisitsToFirstMacroConversion";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_DAYS_TO_FIRST_MACRO_CONVERSION"] = "DimensionDaysToFirstMacroConversion";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_IS_RETURNING_CUSTOMER"] = "DimensionIsReturningCustomer";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_ID"] = "DimensionSessionId";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_DATE"] = "DimensionSessionStartDate";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_TRANSACTION_ID"] = "DimensionTransactionId";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_QS_PARAM"] = "DimensionQSParam";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_QS_VALUE"] = "DimensionQSValue";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_QS_PAIR"] = "DimensionQSPair";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_QS"] = "DimensionQS";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_FULL_URL"] = "DimensionFullURL";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_QS_PARAM"] = "DimensionLandingPageQSParam";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_QS_VALUE"] = "DimensionLandingPageQSValue";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_QS_PAIR"] = "DimensionLandingPageQSPair";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_QS"] = "DimensionLandingPageQS";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_FULL_URL"] = "DimensionLandingPageFullURL";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_REFERRER"] = "DimensionReferrer";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_REFERRER_PATH"] = "DimensionReferrerPath";
    ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum["DIMENSION_REFERRER_DOMAIN"] = "DimensionReferrerDomain";
})(ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum || (ChartServiceReadChartPayloadDisplayOptionsAvailableDimensionsEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum;
(function (ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum) {
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_NOT_SET"] = "DimensionNotSet";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_BY_DAY"] = "DimensionSessionStartByDay";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LOCATION"] = "DimensionLocation";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_BROWSER"] = "DimensionBrowser";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_OS"] = "DimensionOS";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_DEVICE"] = "DimensionDevice";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_CHANNEL"] = "DimensionChannel";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_USER_TYPE"] = "DimensionUserType";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_COUNTRY"] = "DimensionCountry";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_REGION"] = "DimensionRegion";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_CITY"] = "DimensionCity";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LON_LAT"] = "DimensionLonLat";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_AUDIENCE"] = "DimensionSessionAudience";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LEAD_AUDIENCE"] = "DimensionLeadAudience";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_SOURCE"] = "DimensionSource";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_MEDIUM"] = "DimensionMedium";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_EXTERNAL_CAMPAIGN"] = "DimensionExternalCampaign";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_CONTENT"] = "DimensionContent";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_TERM"] = "DimensionTerm";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_PATH"] = "DimensionPath";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_PRODUCT_NAME"] = "DimensionProductName";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_PRODUCT_CATEGORY"] = "DimensionProductCategory";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_PRODUCT_BRAND"] = "DimensionProductBrand";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_JOB_TITLE"] = "DimensionJobTitle";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_JOB_CATEGORY"] = "DimensionJobCategory";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_PRODUCT"] = "DimensionProduct";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_JOB"] = "DimensionJob";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LOCATION_PAGE_TYPE"] = "DimensionLocationPageType";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_PATH"] = "DimensionLandingPagePath";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingPageLocationTaggerValue";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_LOCATION"] = "DimensionLandingPageLocation";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_TYPE"] = "DimensionLandingPageType";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LOCATION_TAGGER_VALUE"] = "DimensionLocationTaggerValue";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_NEXT_PAGE_PATH"] = "DimensionLandingNextPagePath";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingNextPageLocationTaggerValue";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION"] = "DimensionLandingNextPageLocation";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_NEXT_PAGE_TYPE"] = "DimensionLandingNextPageType";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_BY_HOUR"] = "DimensionSessionStartByHour";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_BY_WEEK"] = "DimensionSessionStartByWeek";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_BY_MONTH"] = "DimensionSessionStartByMonth";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_BY_WEEKDAY"] = "DimensionSessionStartByWeekday";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_MICRO_CONVERSION"] = "DimensionMicroConversion";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_MACRO_CONVERSION"] = "DimensionMacroConversion";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_CRAWLER"] = "DimensionCrawler";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_DAYS_TO_SECOND_VISIT"] = "DimensionDaysToSecondVisit";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_JOURNEY"] = "DimensionSessionJourney";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LEAD_STATUS"] = "DimensionLeadStatus";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LOCATION_TAGGER"] = "DimensionLocationTagger";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_SOURCE_BUCKET"] = "DimensionSourceBucket";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_LANDING_PAGE_LOCATION"] = "DimensionSessionLandingPageLocation";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_BROWSER_VERSION"] = "DimensionBrowserVersion";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_BROWSER_WITH_VERSION"] = "DimensionBrowserWithVersion";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_CHANNEL_MACRO_CONVERSION_JOURNEY"] = "DimensionChannelMacroConversionJourney";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_CONVERTING_SESSION_JOURNEY"] = "DimensionConvertingSessionJourney";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_VISITS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionVisitsSincePriorMacroConversion";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_DAYS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionDaysSincePriorMacroConversion";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_CHANNEL_SALES_JOURNEY"] = "DimensionChannelSalesJourney";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_SALES_SESSION_JOURNEY"] = "DimensionSalesSessionJourney";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER"] = "DimensionLandingPageLocationTagger";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER"] = "DimensionLandingNextPageLocationTagger";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_LANDING_PAGE_PATH"] = "DimensionSessionLandingPagePath";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_CAMPAIGN"] = "DimensionCampaign";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_CAMPAIGN_LEAD_STATUS"] = "DimensionCampaignLeadStatus";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_CAMPAIGN_TYPE"] = "DimensionCampaignType";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_AB_CAMPAIGN"] = "DimensionABCampaign";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_DOMAIN"] = "DimensionDomain";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_PERSONALISATION"] = "DimensionPersonalisation";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_VISITS_TO_FIRST_MACRO_CONVERSION"] = "DimensionVisitsToFirstMacroConversion";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_DAYS_TO_FIRST_MACRO_CONVERSION"] = "DimensionDaysToFirstMacroConversion";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_IS_RETURNING_CUSTOMER"] = "DimensionIsReturningCustomer";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_ID"] = "DimensionSessionId";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_DATE"] = "DimensionSessionStartDate";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_TRANSACTION_ID"] = "DimensionTransactionId";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_QS_PARAM"] = "DimensionQSParam";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_QS_VALUE"] = "DimensionQSValue";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_QS_PAIR"] = "DimensionQSPair";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_QS"] = "DimensionQS";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_FULL_URL"] = "DimensionFullURL";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_QS_PARAM"] = "DimensionLandingPageQSParam";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_QS_VALUE"] = "DimensionLandingPageQSValue";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_QS_PAIR"] = "DimensionLandingPageQSPair";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_QS"] = "DimensionLandingPageQS";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_FULL_URL"] = "DimensionLandingPageFullURL";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_REFERRER"] = "DimensionReferrer";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_REFERRER_PATH"] = "DimensionReferrerPath";
    ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum["DIMENSION_REFERRER_DOMAIN"] = "DimensionReferrerDomain";
})(ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum || (ChartServiceReadChartPayloadDisplayOptionsFilterDropdownsEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ChartServiceReadChartPayloadDisplayOptionsLayoutEnum;
(function (ChartServiceReadChartPayloadDisplayOptionsLayoutEnum) {
    ChartServiceReadChartPayloadDisplayOptionsLayoutEnum["HORIZONTAL"] = "horizontal";
    ChartServiceReadChartPayloadDisplayOptionsLayoutEnum["VERTICAL"] = "vertical";
    ChartServiceReadChartPayloadDisplayOptionsLayoutEnum["CENTRIC"] = "centric";
    ChartServiceReadChartPayloadDisplayOptionsLayoutEnum["RADIAL"] = "radial";
})(ChartServiceReadChartPayloadDisplayOptionsLayoutEnum || (ChartServiceReadChartPayloadDisplayOptionsLayoutEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum;
(function (ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum) {
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_NOT_SET"] = "DimensionNotSet";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_BY_DAY"] = "DimensionSessionStartByDay";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LOCATION"] = "DimensionLocation";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_BROWSER"] = "DimensionBrowser";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_OS"] = "DimensionOS";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_DEVICE"] = "DimensionDevice";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_CHANNEL"] = "DimensionChannel";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_USER_TYPE"] = "DimensionUserType";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_COUNTRY"] = "DimensionCountry";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_REGION"] = "DimensionRegion";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_CITY"] = "DimensionCity";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LON_LAT"] = "DimensionLonLat";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_AUDIENCE"] = "DimensionSessionAudience";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LEAD_AUDIENCE"] = "DimensionLeadAudience";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_SOURCE"] = "DimensionSource";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_MEDIUM"] = "DimensionMedium";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_EXTERNAL_CAMPAIGN"] = "DimensionExternalCampaign";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_CONTENT"] = "DimensionContent";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_TERM"] = "DimensionTerm";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_PATH"] = "DimensionPath";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_PRODUCT_NAME"] = "DimensionProductName";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_PRODUCT_CATEGORY"] = "DimensionProductCategory";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_PRODUCT_BRAND"] = "DimensionProductBrand";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_JOB_TITLE"] = "DimensionJobTitle";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_JOB_CATEGORY"] = "DimensionJobCategory";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_PRODUCT"] = "DimensionProduct";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_JOB"] = "DimensionJob";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LOCATION_PAGE_TYPE"] = "DimensionLocationPageType";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_PATH"] = "DimensionLandingPagePath";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingPageLocationTaggerValue";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_LOCATION"] = "DimensionLandingPageLocation";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_TYPE"] = "DimensionLandingPageType";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LOCATION_TAGGER_VALUE"] = "DimensionLocationTaggerValue";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_NEXT_PAGE_PATH"] = "DimensionLandingNextPagePath";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingNextPageLocationTaggerValue";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION"] = "DimensionLandingNextPageLocation";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_NEXT_PAGE_TYPE"] = "DimensionLandingNextPageType";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_BY_HOUR"] = "DimensionSessionStartByHour";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_BY_WEEK"] = "DimensionSessionStartByWeek";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_BY_MONTH"] = "DimensionSessionStartByMonth";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_BY_WEEKDAY"] = "DimensionSessionStartByWeekday";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_MICRO_CONVERSION"] = "DimensionMicroConversion";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_MACRO_CONVERSION"] = "DimensionMacroConversion";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_CRAWLER"] = "DimensionCrawler";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_DAYS_TO_SECOND_VISIT"] = "DimensionDaysToSecondVisit";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_JOURNEY"] = "DimensionSessionJourney";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LEAD_STATUS"] = "DimensionLeadStatus";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LOCATION_TAGGER"] = "DimensionLocationTagger";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_SOURCE_BUCKET"] = "DimensionSourceBucket";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_LANDING_PAGE_LOCATION"] = "DimensionSessionLandingPageLocation";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_BROWSER_VERSION"] = "DimensionBrowserVersion";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_BROWSER_WITH_VERSION"] = "DimensionBrowserWithVersion";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_CHANNEL_MACRO_CONVERSION_JOURNEY"] = "DimensionChannelMacroConversionJourney";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_CONVERTING_SESSION_JOURNEY"] = "DimensionConvertingSessionJourney";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_VISITS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionVisitsSincePriorMacroConversion";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_DAYS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionDaysSincePriorMacroConversion";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_CHANNEL_SALES_JOURNEY"] = "DimensionChannelSalesJourney";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_SALES_SESSION_JOURNEY"] = "DimensionSalesSessionJourney";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER"] = "DimensionLandingPageLocationTagger";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER"] = "DimensionLandingNextPageLocationTagger";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_LANDING_PAGE_PATH"] = "DimensionSessionLandingPagePath";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_CAMPAIGN"] = "DimensionCampaign";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_CAMPAIGN_LEAD_STATUS"] = "DimensionCampaignLeadStatus";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_CAMPAIGN_TYPE"] = "DimensionCampaignType";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_AB_CAMPAIGN"] = "DimensionABCampaign";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_DOMAIN"] = "DimensionDomain";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_PERSONALISATION"] = "DimensionPersonalisation";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_VISITS_TO_FIRST_MACRO_CONVERSION"] = "DimensionVisitsToFirstMacroConversion";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_DAYS_TO_FIRST_MACRO_CONVERSION"] = "DimensionDaysToFirstMacroConversion";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_IS_RETURNING_CUSTOMER"] = "DimensionIsReturningCustomer";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_ID"] = "DimensionSessionId";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_DATE"] = "DimensionSessionStartDate";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_TRANSACTION_ID"] = "DimensionTransactionId";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_QS_PARAM"] = "DimensionQSParam";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_QS_VALUE"] = "DimensionQSValue";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_QS_PAIR"] = "DimensionQSPair";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_QS"] = "DimensionQS";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_FULL_URL"] = "DimensionFullURL";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_QS_PARAM"] = "DimensionLandingPageQSParam";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_QS_VALUE"] = "DimensionLandingPageQSValue";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_QS_PAIR"] = "DimensionLandingPageQSPair";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_QS"] = "DimensionLandingPageQS";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_FULL_URL"] = "DimensionLandingPageFullURL";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_REFERRER"] = "DimensionReferrer";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_REFERRER_PATH"] = "DimensionReferrerPath";
    ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum["DIMENSION_REFERRER_DOMAIN"] = "DimensionReferrerDomain";
})(ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum || (ChartServiceReadChartPayloadDisplayOptionsFullAttributesListEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ChartServiceReadChartPayloadCrawlerEnum;
(function (ChartServiceReadChartPayloadCrawlerEnum) {
    ChartServiceReadChartPayloadCrawlerEnum["NO_CRAWLERS"] = "NO_CRAWLERS";
    ChartServiceReadChartPayloadCrawlerEnum["ONLY_CRAWLERS"] = "ONLY_CRAWLERS";
    ChartServiceReadChartPayloadCrawlerEnum["WITH_CRAWLERS"] = "WITH_CRAWLERS";
})(ChartServiceReadChartPayloadCrawlerEnum || (ChartServiceReadChartPayloadCrawlerEnum = {}));
