import {
  ApiProjectSubscriptionStatus,
  ApiSimpleContract,
  ApiSimpleProjectSubscription,
} from "@incendium/api";
import {
  Alert,
  Button,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { CenterPage } from "Components/CenterPage/CenterPage";
import Loading from "Components/Loading/Loading";
import StyledDrawer, {
  StyledDrawerContainer,
} from "Components/UI/StyledDrawer";
import {
  getContract,
  getProjectSubscrition,
  markSubscriptionAsSeen,
  SubscriptionCard,
} from "features/subscription";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

function PaymentPage() {
  const { search } = useLocation();
  const { projectId } = useParams<{ projectId: string }>();
  const [subscription, setSubscription] =
    useState<ApiSimpleProjectSubscription | null>(null);
  const [contract, setContract] = useState<ApiSimpleContract | null>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [terms, setTerms] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [paid, setPaid] = useState(false);

  const loadSubcription = useCallback(async (projectId: number) => {
    const res = await getProjectSubscrition(projectId);
    setSubscription(res);
  }, []);

  const loadContract = useCallback(async (contractId: string) => {
    const res = await getContract(contractId);
    setContract(res);
  }, []);

  const markAsSeen = useCallback((projectSubscriptionId: string) => {
    markSubscriptionAsSeen(projectSubscriptionId);
  }, []);

  const goToPayment = useCallback(() => {
    window.location.href = subscription?.paymentLink || "";
  }, [subscription]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    loadSubcription(Number(projectId));
  }, [projectId, loadSubcription]);

  useEffect(() => {
    if (subscription?.id) {
      markAsSeen(subscription.id);

      if (subscription.subscription) {
        loadContract(subscription.subscription.contractId!);
      }

      if (subscription?.paymentLinkQr) {
        const base64Data = subscription?.paymentLinkQr.startsWith(
          "data:image/png;base64,"
        )
          ? subscription?.paymentLinkQr
          : `data:image/png;base64,${subscription?.paymentLinkQr}`;

        setImageSrc(base64Data);
      }
    }
  }, [subscription, markAsSeen, loadContract]);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    if (searchParams.has("paid")) {
      if (searchParams.get("paid") === "y") {
        setPaid(true);
      } else {
        setPaid(false);
      }
      return;
    }
    if (
      subscription?.status ===
      ApiProjectSubscriptionStatus.PROJECT_SUBSCRIPTION_STATUS_PAID
    ) {
      setPaid(true);
    }
  }, [subscription, search]);

  if (!subscription?.subscription) {
    return <Loading fullHeight />;
  }

  return (
    <CenterPage>
      {paid ? (
        <Typography>You have already paid</Typography>
      ) : (
        <Stack>
          <Alert severity="warning">
            You shall be redirected to Stripe, on payment you shall be brought
            back to Incendium.
          </Alert>
          <Stack direction={"row"} alignItems="center">
            <FormControlLabel
              componentsProps={{
                typography: {
                  variant: "body1",
                },
              }}
              control={
                <Switch
                  checked={terms}
                  onChange={(e, checked) => {
                    setTerms(true);
                  }}
                />
              }
              label="Accept terms"
            />
            <Button variant="text" onClick={() => setOpen(true)}>
              see terms
            </Button>
          </Stack>
          <Grid mt={3} container spacing={4} sx={{ maxWidth: 1200 }}>
            <Grid item xs={6}>
              <SubscriptionCard subscription={subscription?.subscription} />
              <Typography mt={5}>
                Clicking this button to be taken to stripe
              </Typography>
              <Button onClick={goToPayment} fullWidth disabled={!terms}>
                Click to pay
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Typography>Or scan the QR code</Typography>
              {imageSrc ? (
                <img
                  style={{ opacity: terms ? 1 : 0.05 }}
                  height={400}
                  src={imageSrc}
                  alt="Fetched from API"
                />
              ) : (
                <p>Loading image...</p>
              )}
            </Grid>
          </Grid>
        </Stack>
      )}
      <StyledDrawer open={open} onClose={onClose}>
        <StyledDrawerContainer width={900}>
          <div dangerouslySetInnerHTML={{ __html: contract?.body || "" }} />
        </StyledDrawerContainer>
      </StyledDrawer>
    </CenterPage>
  );
}

export default PaymentPage;
