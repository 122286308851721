/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApiListAllConversionsResponseFromJSON, ApiListProjectSettingsResponseFromJSON, ApiListProjectsResponseFromJSON, ApiProjectFromJSON, ApiProjectInputToJSON, ApiProjectSettingFromJSON, ApiProjectSettingPayloadToJSON, ApiProjectStatsResponseFromJSON, } from '../models';
/**
 *
 */
export class ProjectsServiceApi extends runtime.BaseAPI {
    /**
     */
    projectsServiceCreateProjectRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling projectsServiceCreateProject.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling projectsServiceCreateProject.');
            }
            const queryParameters = {};
            if (requestParameters.projectId !== undefined) {
                queryParameters['projectId'] = requestParameters.projectId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/projects/v1/clients/{clientId}/projects`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiProjectInputToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiProjectFromJSON(jsonValue));
        });
    }
    /**
     */
    projectsServiceCreateProject(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.projectsServiceCreateProjectRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    projectsServiceCreateProjectSettingRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling projectsServiceCreateProjectSetting.');
            }
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling projectsServiceCreateProjectSetting.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling projectsServiceCreateProjectSetting.');
            }
            const queryParameters = {};
            if (requestParameters.settingId !== undefined) {
                queryParameters['settingId'] = requestParameters.settingId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/projects/v1/clients/{clientId}/projects/{projectId}/settings`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiProjectSettingPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiProjectSettingFromJSON(jsonValue));
        });
    }
    /**
     */
    projectsServiceCreateProjectSetting(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.projectsServiceCreateProjectSettingRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    projectsServiceDeleteProjectRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling projectsServiceDeleteProject.');
            }
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling projectsServiceDeleteProject.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/projects/v1/clients/{clientId}/projects/{projectId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    projectsServiceDeleteProject(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.projectsServiceDeleteProjectRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    projectsServiceGetProjectStatsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling projectsServiceGetProjectStats.');
            }
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling projectsServiceGetProjectStats.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/projects/v1/clients/{clientId}/projects/{projectId}:stats`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiProjectStatsResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    projectsServiceGetProjectStats(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.projectsServiceGetProjectStatsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    projectsServiceListAllProjectsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/projects/v1/projects`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListProjectsResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    projectsServiceListAllProjects(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.projectsServiceListAllProjectsRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * returns list of conversion with conversion ConversionState\'s for project
     */
    projectsServiceListProjectConversionsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling projectsServiceListProjectConversions.');
            }
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling projectsServiceListProjectConversions.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/projects/v1/clients/{clientId}/projects/{projectId}/conversions`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListAllConversionsResponseFromJSON(jsonValue));
        });
    }
    /**
     * returns list of conversion with conversion ConversionState\'s for project
     */
    projectsServiceListProjectConversions(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.projectsServiceListProjectConversionsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    projectsServiceListProjectSettingsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling projectsServiceListProjectSettings.');
            }
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling projectsServiceListProjectSettings.');
            }
            const queryParameters = {};
            if (requestParameters.settingId !== undefined) {
                queryParameters['settingId'] = requestParameters.settingId;
            }
            if (requestParameters.payloadKey !== undefined) {
                queryParameters['payload.key'] = requestParameters.payloadKey;
            }
            if (requestParameters.payloadValue !== undefined) {
                queryParameters['payload.value'] = requestParameters.payloadValue;
            }
            if (requestParameters.payloadProjectTypeUnused !== undefined) {
                queryParameters['payload.projectTypeUnused'] = requestParameters.payloadProjectTypeUnused;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/projects/v1/clients/{clientId}/projects/{projectId}/settings`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListProjectSettingsResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    projectsServiceListProjectSettings(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.projectsServiceListProjectSettingsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    projectsServiceListProjectsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling projectsServiceListProjects.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/projects/v1/clients/{clientId}/projects`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListProjectsResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    projectsServiceListProjects(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.projectsServiceListProjectsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    projectsServiceReadProjectRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling projectsServiceReadProject.');
            }
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling projectsServiceReadProject.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/projects/v1/clients/{clientId}/projects/{projectId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiProjectFromJSON(jsonValue));
        });
    }
    /**
     */
    projectsServiceReadProject(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.projectsServiceReadProjectRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    projectsServiceReadProjectSettingRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling projectsServiceReadProjectSetting.');
            }
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling projectsServiceReadProjectSetting.');
            }
            if (requestParameters.settingId === null || requestParameters.settingId === undefined) {
                throw new runtime.RequiredError('settingId', 'Required parameter requestParameters.settingId was null or undefined when calling projectsServiceReadProjectSetting.');
            }
            const queryParameters = {};
            if (requestParameters.payloadKey !== undefined) {
                queryParameters['payload.key'] = requestParameters.payloadKey;
            }
            if (requestParameters.payloadValue !== undefined) {
                queryParameters['payload.value'] = requestParameters.payloadValue;
            }
            if (requestParameters.payloadProjectTypeUnused !== undefined) {
                queryParameters['payload.projectTypeUnused'] = requestParameters.payloadProjectTypeUnused;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/projects/v1/clients/{clientId}/projects/{projectId}/settings/{settingId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"settingId"}}`, encodeURIComponent(String(requestParameters.settingId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiProjectSettingFromJSON(jsonValue));
        });
    }
    /**
     */
    projectsServiceReadProjectSetting(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.projectsServiceReadProjectSettingRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    projectsServiceReadProjectSettingByKeyRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling projectsServiceReadProjectSettingByKey.');
            }
            if (requestParameters.key === null || requestParameters.key === undefined) {
                throw new runtime.RequiredError('key', 'Required parameter requestParameters.key was null or undefined when calling projectsServiceReadProjectSettingByKey.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/projects/v1/projects/{projectId}/settings/key/{key}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiProjectSettingFromJSON(jsonValue));
        });
    }
    /**
     */
    projectsServiceReadProjectSettingByKey(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.projectsServiceReadProjectSettingByKeyRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    projectsServiceUpdateProjectRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling projectsServiceUpdateProject.');
            }
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling projectsServiceUpdateProject.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling projectsServiceUpdateProject.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/projects/v1/clients/{clientId}/projects/{projectId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiProjectInputToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiProjectFromJSON(jsonValue));
        });
    }
    /**
     */
    projectsServiceUpdateProject(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.projectsServiceUpdateProjectRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    projectsServiceUpdateProjectSettingRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling projectsServiceUpdateProjectSetting.');
            }
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling projectsServiceUpdateProjectSetting.');
            }
            if (requestParameters.settingId === null || requestParameters.settingId === undefined) {
                throw new runtime.RequiredError('settingId', 'Required parameter requestParameters.settingId was null or undefined when calling projectsServiceUpdateProjectSetting.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling projectsServiceUpdateProjectSetting.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/projects/v1/clients/{clientId}/projects/{projectId}/settings/{settingId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"settingId"}}`, encodeURIComponent(String(requestParameters.settingId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiProjectSettingPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiProjectSettingFromJSON(jsonValue));
        });
    }
    /**
     */
    projectsServiceUpdateProjectSetting(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.projectsServiceUpdateProjectSettingRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
/**
    * @export
    * @enum {string}
    */
export var ProjectsServiceListProjectSettingsPayloadKeyEnum;
(function (ProjectsServiceListProjectSettingsPayloadKeyEnum) {
    ProjectsServiceListProjectSettingsPayloadKeyEnum["SETTING_UNSPECIFIED"] = "SETTING_UNSPECIFIED";
    ProjectsServiceListProjectSettingsPayloadKeyEnum["BLOCKED_IPS"] = "BLOCKED_IPS";
    ProjectsServiceListProjectSettingsPayloadKeyEnum["ENGAGEMENT_SCORE"] = "ENGAGEMENT_SCORE";
    ProjectsServiceListProjectSettingsPayloadKeyEnum["IGNORE_REFERRERS"] = "IGNORE_REFERRERS";
    ProjectsServiceListProjectSettingsPayloadKeyEnum["LEAD_SCORE_MAX"] = "LEAD_SCORE_MAX";
    ProjectsServiceListProjectSettingsPayloadKeyEnum["RETENTION_LIFETIME_MONTHS"] = "RETENTION_LIFETIME_MONTHS";
    ProjectsServiceListProjectSettingsPayloadKeyEnum["RETENTION_MAX_CHANCE"] = "RETENTION_MAX_CHANCE";
    ProjectsServiceListProjectSettingsPayloadKeyEnum["RETENTION_GRACE_PERIOD_MONTHS"] = "RETENTION_GRACE_PERIOD_MONTHS";
    ProjectsServiceListProjectSettingsPayloadKeyEnum["PAGE_USE_QUERY_STRING"] = "PAGE_USE_QUERY_STRING";
    ProjectsServiceListProjectSettingsPayloadKeyEnum["SHOW_AWARENESS_ACQUISTION_RETENTION_INSIGHTS"] = "SHOW_AWARENESS_ACQUISTION_RETENTION_INSIGHTS";
    ProjectsServiceListProjectSettingsPayloadKeyEnum["PROJECT_TYPE"] = "PROJECT_TYPE";
    ProjectsServiceListProjectSettingsPayloadKeyEnum["USE_DATALAYER_SALES"] = "USE_DATALAYER_SALES";
    ProjectsServiceListProjectSettingsPayloadKeyEnum["FB_PIXEL"] = "FB_PIXEL";
    ProjectsServiceListProjectSettingsPayloadKeyEnum["UI_STATE"] = "UI_STATE";
    ProjectsServiceListProjectSettingsPayloadKeyEnum["USE_DATALAYER_PAGEVIEWS"] = "USE_DATALAYER_PAGEVIEWS";
    ProjectsServiceListProjectSettingsPayloadKeyEnum["USE_DATALAYER_CART_EVENTS"] = "USE_DATALAYER_CART_EVENTS";
    ProjectsServiceListProjectSettingsPayloadKeyEnum["USE_DATALAYER_CHECKOUT_EVENTS"] = "USE_DATALAYER_CHECKOUT_EVENTS";
    ProjectsServiceListProjectSettingsPayloadKeyEnum["USE_ECWID_PIXEL"] = "USE_ECWID_PIXEL";
})(ProjectsServiceListProjectSettingsPayloadKeyEnum || (ProjectsServiceListProjectSettingsPayloadKeyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ProjectsServiceListProjectSettingsPayloadProjectTypeUnusedEnum;
(function (ProjectsServiceListProjectSettingsPayloadProjectTypeUnusedEnum) {
    ProjectsServiceListProjectSettingsPayloadProjectTypeUnusedEnum["TYPE_UNSPECIFIED"] = "TypeUnspecified";
    ProjectsServiceListProjectSettingsPayloadProjectTypeUnusedEnum["TYPE_ECOMMERCE"] = "TypeEcommerce";
    ProjectsServiceListProjectSettingsPayloadProjectTypeUnusedEnum["TYPE_RECRUITMENT"] = "TypeRecruitment";
    ProjectsServiceListProjectSettingsPayloadProjectTypeUnusedEnum["TYPE_B2_B"] = "TypeB2B";
})(ProjectsServiceListProjectSettingsPayloadProjectTypeUnusedEnum || (ProjectsServiceListProjectSettingsPayloadProjectTypeUnusedEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ProjectsServiceReadProjectSettingPayloadKeyEnum;
(function (ProjectsServiceReadProjectSettingPayloadKeyEnum) {
    ProjectsServiceReadProjectSettingPayloadKeyEnum["SETTING_UNSPECIFIED"] = "SETTING_UNSPECIFIED";
    ProjectsServiceReadProjectSettingPayloadKeyEnum["BLOCKED_IPS"] = "BLOCKED_IPS";
    ProjectsServiceReadProjectSettingPayloadKeyEnum["ENGAGEMENT_SCORE"] = "ENGAGEMENT_SCORE";
    ProjectsServiceReadProjectSettingPayloadKeyEnum["IGNORE_REFERRERS"] = "IGNORE_REFERRERS";
    ProjectsServiceReadProjectSettingPayloadKeyEnum["LEAD_SCORE_MAX"] = "LEAD_SCORE_MAX";
    ProjectsServiceReadProjectSettingPayloadKeyEnum["RETENTION_LIFETIME_MONTHS"] = "RETENTION_LIFETIME_MONTHS";
    ProjectsServiceReadProjectSettingPayloadKeyEnum["RETENTION_MAX_CHANCE"] = "RETENTION_MAX_CHANCE";
    ProjectsServiceReadProjectSettingPayloadKeyEnum["RETENTION_GRACE_PERIOD_MONTHS"] = "RETENTION_GRACE_PERIOD_MONTHS";
    ProjectsServiceReadProjectSettingPayloadKeyEnum["PAGE_USE_QUERY_STRING"] = "PAGE_USE_QUERY_STRING";
    ProjectsServiceReadProjectSettingPayloadKeyEnum["SHOW_AWARENESS_ACQUISTION_RETENTION_INSIGHTS"] = "SHOW_AWARENESS_ACQUISTION_RETENTION_INSIGHTS";
    ProjectsServiceReadProjectSettingPayloadKeyEnum["PROJECT_TYPE"] = "PROJECT_TYPE";
    ProjectsServiceReadProjectSettingPayloadKeyEnum["USE_DATALAYER_SALES"] = "USE_DATALAYER_SALES";
    ProjectsServiceReadProjectSettingPayloadKeyEnum["FB_PIXEL"] = "FB_PIXEL";
    ProjectsServiceReadProjectSettingPayloadKeyEnum["UI_STATE"] = "UI_STATE";
    ProjectsServiceReadProjectSettingPayloadKeyEnum["USE_DATALAYER_PAGEVIEWS"] = "USE_DATALAYER_PAGEVIEWS";
    ProjectsServiceReadProjectSettingPayloadKeyEnum["USE_DATALAYER_CART_EVENTS"] = "USE_DATALAYER_CART_EVENTS";
    ProjectsServiceReadProjectSettingPayloadKeyEnum["USE_DATALAYER_CHECKOUT_EVENTS"] = "USE_DATALAYER_CHECKOUT_EVENTS";
    ProjectsServiceReadProjectSettingPayloadKeyEnum["USE_ECWID_PIXEL"] = "USE_ECWID_PIXEL";
})(ProjectsServiceReadProjectSettingPayloadKeyEnum || (ProjectsServiceReadProjectSettingPayloadKeyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ProjectsServiceReadProjectSettingPayloadProjectTypeUnusedEnum;
(function (ProjectsServiceReadProjectSettingPayloadProjectTypeUnusedEnum) {
    ProjectsServiceReadProjectSettingPayloadProjectTypeUnusedEnum["TYPE_UNSPECIFIED"] = "TypeUnspecified";
    ProjectsServiceReadProjectSettingPayloadProjectTypeUnusedEnum["TYPE_ECOMMERCE"] = "TypeEcommerce";
    ProjectsServiceReadProjectSettingPayloadProjectTypeUnusedEnum["TYPE_RECRUITMENT"] = "TypeRecruitment";
    ProjectsServiceReadProjectSettingPayloadProjectTypeUnusedEnum["TYPE_B2_B"] = "TypeB2B";
})(ProjectsServiceReadProjectSettingPayloadProjectTypeUnusedEnum || (ProjectsServiceReadProjectSettingPayloadProjectTypeUnusedEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ProjectsServiceReadProjectSettingByKeyKeyEnum;
(function (ProjectsServiceReadProjectSettingByKeyKeyEnum) {
    ProjectsServiceReadProjectSettingByKeyKeyEnum["SETTING_UNSPECIFIED"] = "SETTING_UNSPECIFIED";
    ProjectsServiceReadProjectSettingByKeyKeyEnum["BLOCKED_IPS"] = "BLOCKED_IPS";
    ProjectsServiceReadProjectSettingByKeyKeyEnum["ENGAGEMENT_SCORE"] = "ENGAGEMENT_SCORE";
    ProjectsServiceReadProjectSettingByKeyKeyEnum["IGNORE_REFERRERS"] = "IGNORE_REFERRERS";
    ProjectsServiceReadProjectSettingByKeyKeyEnum["LEAD_SCORE_MAX"] = "LEAD_SCORE_MAX";
    ProjectsServiceReadProjectSettingByKeyKeyEnum["RETENTION_LIFETIME_MONTHS"] = "RETENTION_LIFETIME_MONTHS";
    ProjectsServiceReadProjectSettingByKeyKeyEnum["RETENTION_MAX_CHANCE"] = "RETENTION_MAX_CHANCE";
    ProjectsServiceReadProjectSettingByKeyKeyEnum["RETENTION_GRACE_PERIOD_MONTHS"] = "RETENTION_GRACE_PERIOD_MONTHS";
    ProjectsServiceReadProjectSettingByKeyKeyEnum["PAGE_USE_QUERY_STRING"] = "PAGE_USE_QUERY_STRING";
    ProjectsServiceReadProjectSettingByKeyKeyEnum["SHOW_AWARENESS_ACQUISTION_RETENTION_INSIGHTS"] = "SHOW_AWARENESS_ACQUISTION_RETENTION_INSIGHTS";
    ProjectsServiceReadProjectSettingByKeyKeyEnum["PROJECT_TYPE"] = "PROJECT_TYPE";
    ProjectsServiceReadProjectSettingByKeyKeyEnum["USE_DATALAYER_SALES"] = "USE_DATALAYER_SALES";
    ProjectsServiceReadProjectSettingByKeyKeyEnum["FB_PIXEL"] = "FB_PIXEL";
    ProjectsServiceReadProjectSettingByKeyKeyEnum["UI_STATE"] = "UI_STATE";
    ProjectsServiceReadProjectSettingByKeyKeyEnum["USE_DATALAYER_PAGEVIEWS"] = "USE_DATALAYER_PAGEVIEWS";
    ProjectsServiceReadProjectSettingByKeyKeyEnum["USE_DATALAYER_CART_EVENTS"] = "USE_DATALAYER_CART_EVENTS";
    ProjectsServiceReadProjectSettingByKeyKeyEnum["USE_DATALAYER_CHECKOUT_EVENTS"] = "USE_DATALAYER_CHECKOUT_EVENTS";
    ProjectsServiceReadProjectSettingByKeyKeyEnum["USE_ECWID_PIXEL"] = "USE_ECWID_PIXEL";
})(ProjectsServiceReadProjectSettingByKeyKeyEnum || (ProjectsServiceReadProjectSettingByKeyKeyEnum = {}));
