import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
  ApiPathStatus,
  ApiProject,
  ApiReadLanderResponse,
} from "@incendium/api";
import { AnalyticsOutlined, Delete, Edit } from "@mui/icons-material";
import {
  Box,
  CardContent,
  CardHeader,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
  styled,
  CardMedia,
  BoxProps,
  CardActions,
  Button,
} from "@mui/material";
import GlassCard from "Components/GlassCard/GlassCard";
import { IndentDivider } from "Components/IndentDivider/IndentDivider";
import Loading from "Components/Loading/Loading";
import StatusLabel from "Components/UI/StatusLabel";
import {
  AnalyticsCard,
  useChartData,
  useFormatMetric,
} from "features/analytics";
import { Suspense, useCallback, useMemo } from "react";
import { ReactComponent as NoImage } from "Assets/Images/Illustrations/undraw_void_-3-ggu.svg";
import { ReactComponent as NoImage1 } from "Assets/Images/Illustrations/undraw_blank_canvas_re_2hwy.svg";
import { getDomain } from "features/subdomains/utils";
import { useHistory } from "react-router-dom";
import backgroundImageStat1 from "Assets/backgrounds/stat-1.jpg";
import backgroundImageStat2 from "Assets/backgrounds/stat-2.jpg";
import backgroundImageStat3 from "Assets/backgrounds/stat-3.jpg";
import { appendTrailingSlash } from "Helpers";

const StatCube = styled(Box)<{ chart?: number }>(({ theme, chart }) => {
  const bg =
    chart === 1
      ? backgroundImageStat1
      : chart === 2
      ? backgroundImageStat2
      : backgroundImageStat3;
  return {
    borderRadius: 6,
    flex: 1,
    color: "white",
    backgroundImage: `url(${bg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: 75,
    "& strong": {
      fontSize: 20,
      fontWeight: 600,
      display: "block",
      lineHeight: 1,
      marginTop: 4,
    },
    "& span": {
      fontSize: 12,
      lineHeight: 1,
      color: "rgba(255,255,255,0.8)",
    },
  };
});

const ImageSection = styled(({ children, ...rest }: BoxProps) => (
  <Box {...rest}>
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        "& svg, img": {
          width: "100%",
          height: "100%",
          objectFit: "contain",
        },
      }}
    >
      {children}
    </Box>
  </Box>
))(({ theme }) => ({
  position: "relative",
  width: "100%",
  paddingTop: "56.25%", // 16:9 aspect ratio
  background:
    "linear-gradient(160deg, rgba(0,0,0,0.05) 0%, rgba(0,0,0,0.14) 70%) ",
}));

interface ILightningLanderCardProps {
  project: ApiProject;
  lander: ApiReadLanderResponse;
  onEdit: (l: ApiReadLanderResponse) => void;
  onDelete: (l: ApiReadLanderResponse) => void;
  onActivate: (l: ApiReadLanderResponse) => void;
  onDeactivate: (l: ApiReadLanderResponse) => void;
}

function LightningLanderCard({
  project,
  lander,
  onEdit,
  onDelete,
  onActivate,
  onDeactivate,
}: ILightningLanderCardProps) {
  const history = useHistory();
  const formatMetric = useFormatMetric();

  // todo: dimension need to be either full url or a dimsnion for landers
  const path = useMemo(
    () =>
      (lander.path || "").slice(-1) === "/"
        ? (lander.path || "").slice(0, -1)
        : `/${lander.path}`,
    [lander.path]
  );

  const domain = useMemo(() => getDomain(lander.url || ""), [lander.url]);
  const attributes = useMemo(() => {
    return [
      {
        key: ApiDimension.DIMENSION_PATH,
        value: path,
      },
      {
        key: ApiDimension.DIMENSION_PATH, // path with or without trailing slash
        value: `${path}/`,
      },
      {
        key: ApiDimension.DIMENSION_DOMAIN,
        value: domain,
      },
    ];
  }, [path, domain]);
  const landingAttributes = useMemo(() => {
    return [
      {
        key: ApiDimension.DIMENSION_LANDING_PAGE_PATH,
        value: path,
      },
      {
        key: ApiDimension.DIMENSION_LANDING_PAGE_PATH, // path with or without trailing slash
        value: `${path}/`,
      },
      {
        key: ApiDimension.DIMENSION_DOMAIN,
        value: domain,
      },
    ];
  }, [path, domain]);

  const chart = useMemo(
    () => ({
      name: "",
      dimension: [],
      yAxisKeys: [
        {
          fields: [
            ApiMetric.METRIC_PAGE_VIEWS_COUNT,
            ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE,
            ApiMetric.METRIC_AVERAGE_VIEWED_PERCENTAGE,
            ApiMetric.METRIC_AVERAGE_SCROLL_PERCENTAGE,
          ],
        },
      ],
      attributes,
      includeEmpty: false,
    }),
    [attributes]
  );

  const dateOverride = {
    from: undefined,
    to: undefined,
    lastNDays: 90,
  };

  const { chartData } = useChartData(project, chart, dateOverride);

  const toAnalytics = useCallback(() => {
    history.push(
      `/clients/${project.clientId}/projects/${project.id}/analyse/optimization/lightning-landers/${lander.id}`
    );
  }, [project, lander, history]);

  return (
    <GlassCard disabled={lander.status === ApiPathStatus.PATH_STATUS_DELETING}>
      <CardHeader
        sx={{
          "& .MuiCardHeader-content": {
            overflow: "hidden",
          },
        }}
        action={
          <Stack direction={"row"}>
            <IconButton
              size="small"
              color="primary"
              onClick={() => onEdit(lander)}
            >
              <Edit />
            </IconButton>
            <IconButton
              size="small"
              color="secondary"
              onClick={() => onDelete(lander)}
            >
              <Delete />
            </IconButton>
          </Stack>
        }
        title={
          <Typography variant="subtitle1" noWrap>
            {lander.path}
          </Typography>
        }
        subheader={
          <StatusLabel
            variant="body2"
            value={`${lander.status}`}
            mapping={{
              [ApiPathStatus.PATH_STATUS_NONE]: "secondary",
              [ApiPathStatus.PATH_STATUS_INACTIVE]: "secondary",
              [ApiPathStatus.PATH_STATUS_ACTIVE]: "success.main",
              [ApiPathStatus.PATH_STATUS_DELETING]: "error.main",
            }}
          >
            {lander.status?.replace("PathStatus", "")}{" "}
            {lander.pending && <> (changes pending)</>}
          </StatusLabel>
        }
      />
      <ImageSection>
        {lander.screenshotUrl && lander.screenshotUrl !== "" ? (
          <CardMedia component="img" image={lander.screenshotUrl} />
        ) : (
          <> {(lander.id as number) % 2 === 0 ? <NoImage /> : <NoImage1 />} </>
        )}
      </ImageSection>

      <CardContent>
        <Stack sx={{ width: "100%", overflow: "hidden" }}>
          <Tooltip arrow title={lander.url}>
            <Link
              href={appendTrailingSlash(`${lander.url}`)}
              target="_blank"
              variant="body2"
              noWrap
              underline="always"
            >
              {appendTrailingSlash(`${lander.url}`)}
            </Link>
          </Tooltip>
          <Typography variant="body2" noWrap>
            Canonical : {lander.canonical || "not set"}
          </Typography>
          <Typography variant="body2">
            {lander.noIndex ? "Your Page wont be Indexed" : "Indexing enabled"}
          </Typography>
        </Stack>
      </CardContent>

      <IndentDivider />
      <CardContent sx={{ background: `rgba(0, 0, 0, 0.02)` }}>
        <Suspense fallback={<Loading />}>
          <Typography variant="subtitle2" mb={1}>
            Primary Metrics for last 90 days
          </Typography>

          <Stack direction={"row"} spacing={1} justifyContent={"space-between"}>
            <Tooltip
              arrow
              title={
                <>
                  avg time on page :{" "}
                  {formatMetric(
                    ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE,
                    Number(
                      chartData?.data[0]
                        ? chartData?.data[0][
                            ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE
                          ]
                        : 0
                    )
                  )}
                </>
              }
            >
              <StatCube chart={1} p={1}>
                <Typography component="strong" color={"inherit"}>
                  {formatMetric(
                    ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE,
                    Number(
                      chartData?.data[0]
                        ? chartData?.data[0][
                            ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE
                          ]
                        : 0
                    )
                  )}
                </Typography>
                <Typography component="span" color={"inherit"}>
                  Avg. Time on Page
                </Typography>
              </StatCube>
            </Tooltip>
            <Tooltip
              arrow
              title={
                <>
                  avg viewed percentage :{" "}
                  {formatMetric(
                    ApiMetric.METRIC_AVERAGE_VIEWED_PERCENTAGE,
                    Number(
                      chartData?.data[0]
                        ? chartData?.data[0][
                            ApiMetric.METRIC_AVERAGE_VIEWED_PERCENTAGE
                          ]
                        : 0
                    )
                  )}
                </>
              }
            >
              <StatCube chart={2} p={1}>
                <Typography component="strong" color={"inherit"}>
                  {formatMetric(
                    ApiMetric.METRIC_AVERAGE_VIEWED_PERCENTAGE,
                    Number(
                      chartData?.data[0]
                        ? chartData?.data[0][
                            ApiMetric.METRIC_AVERAGE_VIEWED_PERCENTAGE
                          ]
                        : 0
                    )
                  )}
                </Typography>
                <Typography component="span" color={"inherit"}>
                  Avg. Viewed
                </Typography>
              </StatCube>
            </Tooltip>
            <Tooltip
              arrow
              title={
                <>
                  avg percentage of page scrolled :{" "}
                  {formatMetric(
                    ApiMetric.METRIC_AVERAGE_SCROLL_PERCENTAGE,
                    Number(
                      chartData?.data[0]
                        ? chartData?.data[0][
                            ApiMetric.METRIC_AVERAGE_SCROLL_PERCENTAGE
                          ]
                        : 0
                    )
                  )}
                </>
              }
            >
              <StatCube chart={3} p={1}>
                <Typography component="strong" color={"inherit"}>
                  {formatMetric(
                    ApiMetric.METRIC_AVERAGE_SCROLL_PERCENTAGE,
                    Number(
                      chartData?.data[0]
                        ? chartData?.data[0][
                            ApiMetric.METRIC_AVERAGE_SCROLL_PERCENTAGE
                          ]
                        : 0
                    )
                  )}
                </Typography>
                <Typography component="span" color={"inherit"}>
                  Avg. Scrolled
                </Typography>
              </StatCube>
            </Tooltip>
          </Stack>
          <Stack mt={2} direction={"row"} sx={{ height: 90 }} spacing={2}>
            <Box>
              <Typography variant="subtitle2">Pageviews</Typography>
              <Typography variant="subtitle1" color={"primary.main"}>
                {formatMetric(
                  ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                  Number(
                    chartData?.data[0]
                      ? chartData?.data[0][ApiMetric.METRIC_PAGE_VIEWS_COUNT]
                      : 0
                  )
                )}
              </Typography>
            </Box>

            <AnalyticsCard
              noBorder
              backgroundColor="transparent"
              noShadow
              noToolbar
              boxProps={{
                padding: 0,
              }}
              overrideDate={dateOverride}
              chart={{
                name: "",
                dimension: [ApiDimension.DIMENSION_SESSION_START_BY_DAY],
                template: ApiChartTemplate.TREND,
                type: ApiChartType.GRAPH,
                attributes,
                yAxisKeys: [
                  {
                    key: "l",
                    fields: [ApiMetric.METRIC_PAGE_VIEWS_COUNT],
                  },
                ],
                displayOptions: {
                  noFilters: true,
                  noLegend: true,
                  noTitle: true,
                  noAxis: true,
                },
              }}
            />
          </Stack>
          <Stack mt={2} sx={{ height: 165 }}>
            <Typography variant="subtitle2">
              Session Conversion Rate.
            </Typography>
            <Box
              sx={{
                flex: "1 0 100%",
                transform: "translateY(-15px)",
              }}
            >
              <AnalyticsCard
                noBorder
                noShadow
                noToolbar
                backgroundColor="transparent"
                boxProps={{
                  padding: 0,
                }}
                overrideDate={dateOverride}
                chart={{
                  name: "",
                  dimension: [ApiDimension.DIMENSION_DEVICE],
                  template: ApiChartTemplate.SNAPSHOT,
                  type: ApiChartType.PIE,
                  attributes: landingAttributes,
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE],
                    },
                  ],
                  displayOptions: {
                    noFilters: true,
                    noLegend: true,
                    noTitle: true,
                    noAxis: true,
                    pieTotalFontSize: 18,
                    showTotals: true,
                  },
                }}
              />
            </Box>
          </Stack>
        </Suspense>
      </CardContent>
      <IndentDivider />
      <CardActions disableSpacing>
        <Stack
          direction={"row"}
          sx={{ width: "100%" }}
          justifyContent="space-between"
        >
          {lander.status === ApiPathStatus.PATH_STATUS_ACTIVE ? (
            <Button
              color="secondary"
              size="small"
              onClick={() => onDeactivate(lander)}
            >
              Deactivate
            </Button>
          ) : (
            <Button
              color="secondary"
              size="small"
              onClick={() => onActivate(lander)}
            >
              Activate
            </Button>
          )}
          <Button
            endIcon={<AnalyticsOutlined />}
            onClick={toAnalytics}
            size="small"
          >
            view detailed analytics
          </Button>
        </Stack>
      </CardActions>
    </GlassCard>
  );
}

export default LightningLanderCard;
