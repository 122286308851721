import { CallbackOrVal } from "Interfaces";
import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

function useAppendSearchState<T>(
  key: string,
  initialValue: T
): [T, (v: CallbackOrVal<T>) => void] {
  const location = useLocation();
  const history = useHistory();

  const getInitialValue = (): T => {
    const params = new URLSearchParams(location.search);
    const urlValue = params.get(key);
    return (urlValue as T) || initialValue;
  };

  const [value, setValue] = useState<T>(getInitialValue);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const currentUrlValue = params.get(key);
    if (value && currentUrlValue === value) {
      return;
    }

    if (value) {
      params.set(key, value.toString());
    } else {
      params.delete(key);
    }
    history.replace({ search: params.toString() });
  }, [value, location.search, history, key]);

  return [value, setValue];
}

export default useAppendSearchState;
