import { ApiChartYAxisKey, ApiDimension, ApiMetric } from "@incendium/api";
import { MoreHoriz } from "@mui/icons-material";
import { Box, IconButton, Stack, useTheme } from "@mui/material";
import { SparkLineChart } from "@mui/x-charts";
import IncreaseDecrease from "Components/IncreaseDecrease/IncreaseDecrease";
import { StatCardTypography } from "Components/UI/StatCard";
import {
  AnalyticsCardToolBar,
  AnalyticsStatCard,
  metricToName,
  percentageTopChangeByMetric,
  useFormatMetric,
} from "features/analytics";
import { AnimatePresence, motion } from "framer-motion";
import { IChart } from "Interfaces";
import { useMemo, useState } from "react";

interface IAnalyticsChartStatCardProps {
  chart: IChart;
  fullHeight?: boolean;
  onEdit?: () => Promise<void>;
  onDelete?: () => Promise<void>;
  noToolbar?: boolean;
  topDimension?: ApiDimension;
}

function AnalyticsChartStatCard({
  chart,
  fullHeight,
  onEdit,
  onDelete,
  noToolbar,
  topDimension,
}: IAnalyticsChartStatCardProps) {
  const theme = useTheme();
  const formatMetric = useFormatMetric();
  const [showToolBar, setShowToolBar] = useState(false);
  const metrics = useMemo(() => {
    const fields =
      chart.yAxisKeys.length > 0
        ? (chart.yAxisKeys[0] as ApiChartYAxisKey).fields
        : null;
    if (!fields) {
      return [];
    }
    return fields as ApiMetric[];
  }, [chart]);

  const noBg = useMemo(
    () => !!chart.displayOptions?.statNoBg,
    [chart.displayOptions?.statNoBg]
  );

  return (
    <>
      <AnalyticsStatCard
        fullHeight={fullHeight}
        chart={chart}
        comparison={chart.comparison}
        includeTrendedSpark={metrics.length === 1}
        noBg={noBg}
        renderBody={(data, comparisonData, trendedChartData) => {
          const changeOne = percentageTopChangeByMetric(
            data,
            comparisonData || [],
            metrics[0]
          );
          const changeTwo = percentageTopChangeByMetric(
            data,
            comparisonData || [],
            metrics[1]
          );

          const trendPoints = trendedChartData
            ? trendedChartData.map((t) => Number(t[metrics[0]]))
            : [];

          return metrics.length > 1 ? (
            <>
              <Stack direction={"row"} alignItems="baseline" spacing={1} mb={1}>
                <StatCardTypography
                  size="medium"
                  color={noBg ? "primary" : undefined}
                >
                  {formatMetric(metrics[0], Number(data[0][metrics[0]!]))}
                </StatCardTypography>
                <StatCardTypography
                  size="xs"
                  opactity={0.8}
                  display="inline"
                  color={noBg ? "text.primary" : undefined}
                >
                  {chart.displayOptions?.statTitle || metricToName(metrics[0]!)}
                </StatCardTypography>
                {chart.comparison && typeof changeOne !== "undefined" && (
                  <IncreaseDecrease
                    direction={changeOne >= 0 ? "up" : "down"}
                    value={changeOne}
                    fontSize={16}
                    useThemeColours={noBg}
                  />
                )}
              </Stack>
              <Stack direction={"row"} alignItems="baseline" spacing={1}>
                <StatCardTypography
                  size="medium"
                  color={noBg ? "primary" : undefined}
                >
                  {formatMetric(metrics[1], Number(data[0][metrics[1]!]))}
                </StatCardTypography>
                <StatCardTypography
                  size="xs"
                  opactity={0.8}
                  display="inline"
                  color={noBg ? "text.primary" : undefined}
                >
                  {chart.displayOptions?.statTitle2 ||
                    metricToName(metrics[1]!)}
                </StatCardTypography>
                {chart.comparison && typeof changeTwo !== "undefined" && (
                  <IncreaseDecrease
                    direction={changeTwo >= 0 ? "up" : "down"}
                    value={changeTwo}
                    fontSize={16}
                    useThemeColours={noBg}
                  />
                )}
              </Stack>
            </>
          ) : (
            <Stack
              direction={"row"}
              justifyContent="space-between"
              spacing={1}
              sx={{ position: "relative" }}
            >
              <Stack justifyContent="space-between">
                <StatCardTypography
                  size="xs"
                  fontSize={16}
                  color={noBg ? "text.primary" : undefined}
                  mb={
                    chart.comparison && typeof changeOne !== "undefined" ? 0 : 1
                  }
                >
                  {chart.displayOptions?.statTitle || metricToName(metrics[0]!)}
                </StatCardTypography>
                <Box mt={1}>
                  <StatCardTypography
                    size="large"
                    fontSize={36}
                    color={noBg ? "primary" : undefined}
                  >
                    {topDimension
                      ? `${data[0][topDimension]}`
                      : formatMetric(metrics[0], Number(data[0][metrics[0]!]))}
                  </StatCardTypography>

                  {chart.comparison && typeof changeOne !== "undefined" && (
                    <Stack direction={"row"} alignItems="baseline" spacing={1}>
                      <IncreaseDecrease
                        direction={changeOne >= 0 ? "up" : "down"}
                        value={changeOne}
                        fontSize={16}
                        useThemeColours={noBg}
                      />
                      <StatCardTypography
                        size="xs"
                        fontSize={16}
                        fontWeight={400}
                        color={noBg ? "text.primary" : undefined}
                      >
                        from last period
                      </StatCardTypography>
                    </Stack>
                  )}
                </Box>
              </Stack>
              <Box
                sx={{
                  position: "absolute",
                  height: "100%",
                  width: 200,
                  maxWidth: "50%",
                  right: 0,
                  display: "flex",
                  alignItems: "center",
                  paddingY: 3,
                }}
              >
                <SparkLineChart
                  data={trendPoints}
                  curve="natural"
                  colors={noBg ? [theme.palette.primary.main] : ["#fff"]}
                  yAxis={{
                    min: Math.min(...trendPoints),
                    max: Math.max(...trendPoints),
                  }}
                />
              </Box>
            </Stack>
          );
        }}
      />
      {!noToolbar && (
        <AnimatePresence mode="wait">
          {showToolBar ? (
            <AnalyticsCardToolBar
              setOpen={setShowToolBar}
              chart={chart}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          ) : (
            <Box
              component={motion.div}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              <IconButton
                sx={{
                  position: "absolute",
                  bottom: 0,
                  right: 5,
                  padding: 0.5,
                }}
                onClick={() => setShowToolBar(true)}
              >
                <MoreHoriz sx={{ fontSize: 30, fill: "white" }} />
              </IconButton>
            </Box>
          )}
        </AnimatePresence>
      )}
    </>
  );
}

export default AnalyticsChartStatCard;
