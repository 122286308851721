import { Box, Stack, Typography } from "@mui/material";
import { ChartLegend, useChartData, useFormatMetric } from "features/analytics";
import { StatCardRound, StatCardRoundTypography } from "Components/UI/StatCard";
import StyledBarChart from "Components/StyledBarChart/StyledBarChart";
import { TChartData } from "Interfaces";
import {
  formatPercentage,
  percentageChange,
  percentageOf,
} from "Helpers/percentage";
import { useSelectedProject } from "Hooks";
import { useFromToContext } from "Providers/FromToProvider";
import { useMemo } from "react";
import { Tooltip } from "recharts";

import { ApiDimension, ApiMetric } from "@incendium/api";
import AnalyticsCardOld, {
  AnalyticsCardTitle,
  AnalyticsCardTitleWrapper,
} from "Components/Analytics/AnalyticsCard";
import { AnalyticsSpacing } from "consts";

function JobTopCategoryComp() {
  const { selectedProject } = useSelectedProject();
  const { chartOutput, chartComparisonOutput } = useFromToContext();
  const formatMetric = useFormatMetric();

  const chart = useMemo(
    () => ({
      name: "Top Job Categories Comparison",
      dimension: [ApiDimension.DIMENSION_JOB_CATEGORY],
      yAxisKeys: [
        {
          fields: [ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT],
        },
      ],
      attributes: [],
    }),
    []
  );

  const { chartData: currentChartData } = useChartData(
    selectedProject,
    chart,
    chartOutput
  );
  const { chartData: compChartData } = useChartData(
    selectedProject,
    chart,
    chartComparisonOutput
  );

  const chartData: TChartData[] = useMemo(() => {
    if (!currentChartData || !compChartData) {
      return [];
    }

    return (currentChartData.data || []).map((d) => {
      const o: TChartData = {
        name: d.name || "",
        current: d[ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT],
      };

      const f = (compChartData.data || []).find((v) => v.name === d.name);
      if (f) {
        o.previous = f[ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT];
      }
      return o;
    });
  }, [currentChartData, compChartData]);

  const topApplicationsCurrent = useMemo(
    () =>
      chartData.sort(
        (a, b) => ((b.current as number) || 0) - ((a.current as number) || 0)
      )[0],
    [chartData]
  );
  const topApplicationsPrevious = useMemo(
    () =>
      chartData.sort(
        (a, b) => ((b.previous as number) || 0) - ((a.previous as number) || 0)
      )[0],
    [chartData]
  );

  const topShareOfApplications = useMemo(() => {
    const total = chartData.reduce((agg, v) => agg + (v.current as number), 0);
    return percentageOf(
      (topApplicationsCurrent?.current as number) || 0,
      total
    );
  }, [topApplicationsCurrent, chartData]);

  const biggestDifference = useMemo(() => {
    return chartData
      .filter((v) => v.current || v.previous)
      .map((v) => {
        const diff = percentageChange(
          (v.previous as number) || 0,
          (v.current as number) || 0
        );
        return {
          ...v,
          diff,
        };
      })
      .sort((a, b) => {
        return b.diff - a.diff;
      })[0];
  }, [chartData]);

  return (
    <AnalyticsCardOld>
      <Stack direction={"row"} alignItems="center">
        <Box sx={{ flex: 1 }}>
          <Stack
            mb={3}
            direction={"row"}
            alignItems="baseline"
            spacing={AnalyticsSpacing}
          >
            <AnalyticsCardTitleWrapper>
              <Box>
                <AnalyticsCardTitle title="Top Job Categories Comparison" />
                <Typography variant="body2" color={"secondary"}>
                  See which job categories performed best
                </Typography>
              </Box>
            </AnalyticsCardTitleWrapper>

            <ChartLegend items={["This time Period", "Previous time Period"]} />
          </Stack>

          <StyledBarChart
            data={chartData}
            height={320}
            yAxisKeys={[
              {
                key: "l",
                fields: ["current"],
              },
            ]}
            yAxisProps={[
              {
                tickFormatter: (value) =>
                  formatMetric(
                    ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT,
                    value
                  ),
              },
            ]}
          >
            <Tooltip
              formatter={(value, key) =>
                formatMetric(
                  ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT,
                  Number(value || 0)
                )
              }
            />
          </StyledBarChart>
        </Box>
        <Box
          sx={{
            minWidth: 330,
            height: 390,
            position: "relative",
          }}
        >
          <StatCardRound
            size={217}
            sx={{ position: "absolute", top: 5, left: -20 }}
          >
            <StatCardRoundTypography size="small">
              Looks like
            </StatCardRoundTypography>
            <StatCardRoundTypography size="medium" mb={0.5} lines={2}>
              {topApplicationsCurrent?.name}
            </StatCardRoundTypography>
            <StatCardRoundTypography size="small" lines={3}>
              {topApplicationsCurrent?.name ===
              topApplicationsPrevious?.name ? (
                <>performed best</>
              ) : (
                <>
                  overtook <br /> {topApplicationsPrevious?.name}
                </>
              )}
              <br /> in this period
            </StatCardRoundTypography>
          </StatCardRound>
          <StatCardRound
            size={179}
            backgroundColor={2}
            sx={{ position: "absolute", bottom: 0, right: 10 }}
          >
            <StatCardRoundTypography size="small">
              Applications from
            </StatCardRoundTypography>
            <StatCardRoundTypography size="medium" mb={0.5} lines={1}>
              {biggestDifference?.name}
            </StatCardRoundTypography>
            <StatCardRoundTypography size="small">
              {biggestDifference?.diff >= 0 ? "increased" : "decreased"} by
            </StatCardRoundTypography>
            <StatCardRoundTypography size="medium">
              {formatPercentage(Math.abs(biggestDifference?.diff))}
            </StatCardRoundTypography>
          </StatCardRound>
          <StatCardRound
            size={155}
            backgroundColor={1}
            sx={{ position: "absolute", top: 217 - 155 + 5, right: 10 }}
          >
            <StatCardRoundTypography size="small">
              generating
            </StatCardRoundTypography>
            <StatCardRoundTypography size="medium">
              {formatPercentage(topShareOfApplications)}
            </StatCardRoundTypography>
            <StatCardRoundTypography size="small">
              of the total applications
            </StatCardRoundTypography>
          </StatCardRound>
        </Box>
      </Stack>
    </AnalyticsCardOld>
  );
}

export default JobTopCategoryComp;
