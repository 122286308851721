import { ApiSimpleProduct, ApiSubscriptionTier } from "@incendium/api";
import { Delete, Edit } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import GlassCard from "Components/GlassCard/GlassCard";
import SavingButton from "Components/UI/SavingButton";
import SpacedList from "Components/UI/SpacedList";
import { StyledDrawerContainer } from "Components/UI/StyledDrawer";
import { cell2Icons } from "consts";
import {
  deleteSubscriptionProduct,
  saveSubcriptionProduct,
  useSubscriptionProducts,
  useSubscriptions,
} from "features/subscription";
import { camelToText } from "Helpers/camelToText";
import { enumToArray } from "Helpers/enumToText";
import { truncate } from "Helpers/truncate";
import withInfoColumn from "HoC/withInfoColumn";
import { useNotification } from "Hooks";
import { useConfirmation } from "Hooks/useConfirmation";
import { useSave } from "Hooks/useSave";
import produce from "immer";
import { useCallback, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

interface IProductsPageInnerProps {
  products: ApiSimpleProduct[];
  onEdit: (c: ApiSimpleProduct) => void;
  onDelete: (c: ApiSimpleProduct) => void;
}

function ProductsPageInner({
  products,
  onEdit,
  onDelete,
}: IProductsPageInnerProps) {
  const { subscriptions } = useSubscriptions();

  const hasSub = useCallback(
    (product: ApiSimpleProduct) => {
      return (subscriptions || []).some((v) => v.productId === product.id);
    },
    [subscriptions]
  );

  return (
    <SpacedList title="Products List">
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Tier</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {products.map((product) => (
          <TableRow key={product.id}>
            <TableCell>{product.name}</TableCell>
            <TableCell>{truncate(product.description || "", 30)}</TableCell>
            <TableCell>{product.tier}</TableCell>
            <TableCell align="right" color="primary" sx={{ width: cell2Icons }}>
              <IconButton
                disabled={hasSub(product)}
                size="small"
                color="primary"
                onClick={() => onEdit(product)}
              >
                <Edit />
              </IconButton>
              <IconButton
                disabled={hasSub(product)}
                size="small"
                color="secondary"
                onClick={() => onDelete(product)}
              >
                <Delete />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </SpacedList>
  );
}

function ProductsPage() {
  const { subscriptionProducts, setSubscriptionProducts } =
    useSubscriptionProducts();
  const [product, setProduct] = useState<ApiSimpleProduct>({});
  const { saving, save } = useSave();
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const handleDelete = useConfirmation();

  const onSubmit = useCallback(() => {
    try {
      save(async () => {
        const res = await saveSubcriptionProduct(product);
        setProduct({});
        setSubscriptionProducts((cont) =>
          produce(cont, (draft) => {
            const idx = draft.findIndex((d) => d.id === res.id);
            if (idx >= 0) {
              draft[idx] = res;
            } else {
              draft.push(res);
            }
          })
        );
        showSuccessNotification("Product Saved");
      });
    } catch (error) {
      showErrorNotification("Failed to save Product, please try again");
    }
  }, [
    save,
    product,
    showSuccessNotification,
    showErrorNotification,
    setSubscriptionProducts,
  ]);

  const onEdit = useCallback((c: ApiSimpleProduct) => {
    setProduct(c);
  }, []);

  const onDelete = useCallback(
    (c: ApiSimpleProduct) => {
      handleDelete({
        title: `Are you sure you want to delete this product`,
        body: `This action cannot be undone.`,
        callback: async () => {
          await deleteSubscriptionProduct(c.id || "");
          setSubscriptionProducts((subs) =>
            produce(subs, (draft) => {
              const idx = draft.findIndex((d) => d.id === c.id);
              if (idx >= 0) {
                draft.splice(idx, 1);
              }
            })
          );
          return "Product Deleted";
        },
      });
    },
    [handleDelete, setSubscriptionProducts]
  );

  return withInfoColumn(ProductsPageInner)({
    products: subscriptionProducts,
    onEdit,
    onDelete,
    infoColumn: (
      <ValidatorForm onSubmit={onSubmit}>
        <StyledDrawerContainer>
          <GlassCard noShadow>
            <Stack spacing={2}>
              <Typography variant="subtitle1">Create New Product</Typography>
              <TextValidator
                label="Product Name"
                value={product.name || ""}
                variant="outlined"
                name="name"
                size="small"
                fullWidth
                validators={["required"]}
                errorMessages={["Name is required"]}
                onChange={(e: any) =>
                  setProduct((product) =>
                    produce(product, (draft) => {
                      draft.name = e.target.value;
                    })
                  )
                }
              />
              <TextValidator
                label="Product Description"
                value={product.description || ""}
                variant="outlined"
                name="description"
                size="small"
                fullWidth
                multiline
                rows={2}
                validators={["required"]}
                errorMessages={["Description is required"]}
                onChange={(e: any) =>
                  setProduct((product) =>
                    produce(product, (draft) => {
                      draft.description = e.target.value;
                    })
                  )
                }
              />
              <Autocomplete
                id="tier-picker"
                size="small"
                onChange={(e, value) => {
                  setProduct((sub) =>
                    produce(sub, (draft) => {
                      draft.tier = value;
                    })
                  );
                }}
                disableClearable
                value={
                  product.tier ||
                  ApiSubscriptionTier.SUBSCRIPTION_TIER_ENTERPRISE
                }
                options={enumToArray(ApiSubscriptionTier)}
                getOptionLabel={(o) => camelToText(o)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select a tier"
                    variant="outlined"
                  />
                )}
              />
            </Stack>

            <Stack
              mt={4}
              direction={"row"}
              spacing={2}
              justifyContent="flex-end"
            >
              <Button onClick={(e) => setProduct({})} color="secondary">
                Cancel
              </Button>
              <SavingButton
                variant="contained"
                color="primary"
                type="submit"
                saving={saving}
              >
                Save
              </SavingButton>
            </Stack>
          </GlassCard>
        </StyledDrawerContainer>
      </ValidatorForm>
    ),
  });
}

export default ProductsPage;
