import { Box, Portal, Stack, Typography } from "@mui/material";
import { TabButton } from "Components/UI/TabButton";
import { TabContainer } from "Components/UI/TabContainer";
import { AnimatePresence } from "framer-motion";
import { useCallback, useEffect, useMemo } from "react";
import { PageTitle } from "consts";

interface ITabbedAnalysesPageProps {
  title: string;
  subTitle?: string;
  tabs: string[];
  activeTab: string;
  setActiveTab: (value: React.SetStateAction<string>) => void;
  renderSwitch: () => JSX.Element;
  hideAction?: boolean;
}

function TabbedAnalysesPage({
  title,
  subTitle,
  tabs,
  setActiveTab,
  activeTab,
  renderSwitch,
  hideAction,
}: ITabbedAnalysesPageProps) {
  useEffect(() => {
    if (!activeTab) {
      return;
    }
    const newUrl = `./${activeTab}`;
    window.history.replaceState(
      {
        ...window.history.state,
        as: newUrl,
        url: newUrl,
      },
      "",
      newUrl
    );
  }, [activeTab]);

  const onTabChange = useCallback(
    (value: string) => {
      setActiveTab(value.toLowerCase().replace(" ", "_"));
    },
    [setActiveTab]
  );

  const titleTab = useMemo(
    () => tabs.find((t) => t.toLowerCase().replace(" ", "_") === activeTab),
    [tabs, activeTab]
  );

  return (
    <>
      <Portal container={() => document.getElementById(PageTitle)}>
        <Typography variant="h1">
          {title} - {titleTab}
        </Typography>
        {subTitle && (
          <Typography variant="subtitle2" fontWeight={500}>
            {subTitle}
          </Typography>
        )}
      </Portal>
      <Stack justifyContent={"space-between"} direction="row">
        <TabContainer fullWidth>
          {tabs.map((ins) => (
            <TabButton
              key={ins}
              onClick={() => onTabChange(ins)}
              isActive={ins.toLowerCase().replace(" ", "_") === activeTab}
            >
              {ins}
            </TabButton>
          ))}
        </TabContainer>
        <Box id="subhead-right"></Box>
      </Stack>
      <AnimatePresence>
        <Box my={4}>{renderSwitch()}</Box>
      </AnimatePresence>
    </>
  );
}

export default TabbedAnalysesPage;
