import { alpha } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useCallback, useMemo } from "react";
import {
  getChartColor,
  metricToName,
  useFormatMetric,
} from "features/analytics";
import {
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from "recharts";
import { AnalyticsCardCustomListLabel } from "./Analytics/AnalyticsCard";
import { AnalyticsCardCustomTooltip } from "features/analytics";
import { percentage } from "Helpers/percentage";
import { IStyledChartProps } from "Interfaces";
import { ApiMetric } from "@incendium/api";
import { useAnalyticsContext } from "Providers/AnalyticsProvider";
import { useYWidth } from "Hooks/useAnalyticsAxisDimensions";

interface IStyledScatterChartProps extends IStyledChartProps {
  dimension: string;
  showLabels?: boolean;
}

function StyledScatterChart({
  height,
  aspect,
  data,
  children,
  dimension,
  yAxisKeys,
  showLabels,
  showTooltip,
  tooltipProps,
}: IStyledScatterChartProps) {
  const theme = useTheme();
  const yWidths = useYWidth(data, yAxisKeys);
  const formatMetric = useFormatMetric();

  const { colourMap } = useAnalyticsContext();
  const metrics: string[] = useMemo(() => {
    return (yAxisKeys || [])
      .reduce((agg: any, y) => {
        if (typeof y === "string") {
          return [...agg, y];
        }
        return [...agg, ...(y.fields || [])];
      }, [])
      .filter((v: string) => {
        return v !== "";
      });
  }, [yAxisKeys]);

  const tickFormatter = (metric: ApiMetric) => (v: number) => {
    return formatMetric(metric, v) as string;
  };

  const renderLines = useCallback(() => {
    if (!data.length || !metrics.length) {
      return null;
    }

    return [...metrics.slice(0, 3)].map((metric, i) => {
      const range = data
        .map((d) => d[metric])
        .sort((a, b) => Number(a) - Number(b));

      const max = Number(range[range.length - 1]);
      switch (i) {
        case 0:
          return (
            <XAxis
              key={"x"}
              type="number"
              axisLine={{ stroke: theme.palette.primary.dark, strokeWidth: 2 }}
              dataKey={metric}
              fontSize={12}
              tickFormatter={tickFormatter(metric as ApiMetric)}
              ticks={[
                0,
                percentage(25, max),
                percentage(50, max),
                percentage(75, max),
                max,
              ]}
              domain={[
                (dataMin: number, r: any) => {
                  const minus = percentage(5, Number(range[range.length - 1]));
                  return dataMin === 0 ? -Math.floor(minus) : 0;
                },
                Math.abs(max + percentage(5, max)),
              ]}
              label={{
                value: metricToName(metric as ApiMetric),
                position: "bottom",
              }}
            />
          );
        case 1:
          return (
            <YAxis
              key={"y"}
              type="number"
              dataKey={metric}
              axisLine={{ stroke: theme.palette.primary.dark, strokeWidth: 2 }}
              fontSize={12}
              tickFormatter={tickFormatter(metric as ApiMetric)}
              ticks={[
                0,
                percentage(25, max),
                percentage(50, max),
                percentage(75, max),
                max,
              ]}
              domain={[
                (dataMin: number, r: any) => {
                  const minus = percentage(5, Number(range[range.length - 1]));
                  return dataMin === 0 ? -Math.floor(minus) : 0;
                },
                Math.abs(max + percentage(5, max)),
              ]}
              width={yWidths[0] + 15}
              label={{
                value: metricToName(metric as ApiMetric),
                angle: -90,
                position: "left",
                style: { textAnchor: "middle" },
              }}
            />
          );
        case 2:
          return (
            <ZAxis key="z" type="number" dataKey={metric} range={[100, 3000]} />
          );
      }
    });
  }, [metrics, data]);

  return (
    <ResponsiveContainer
      height={height ? height : aspect ? undefined : "100%"}
      width={"100%"}
      aspect={aspect}
    >
      <ScatterChart
        margin={{
          bottom: 18,
          left: 10,
          right: 15,
          top: 25,
        }}
      >
        <CartesianGrid
          strokeDasharray="6"
          stroke={alpha(theme.palette.info.main, 0.5)}
        />
        {children}
        {renderLines()}
        {showTooltip && (
          <Tooltip
            cursor={{
              strokeDasharray: "3 3",
              stroke: theme.palette.info.main,
            }}
            content={<AnalyticsCardCustomTooltip {...tooltipProps} />}
          />
        )}
        <Scatter
          name={dimension}
          data={data}
          fill={theme.palette.charts[0]}
          opacity={0.8}
          strokeOpacity={1}
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={
                colourMap && colourMap[entry.name]
                  ? colourMap[entry.name]
                  : getChartColor(index, theme.palette.charts)
              }
            />
          ))}
          {showLabels && (
            <LabelList
              dataKey="name"
              content={<AnalyticsCardCustomListLabel />}
            />
          )}
        </Scatter>
      </ScatterChart>
    </ResponsiveContainer>
  );
}

export default StyledScatterChart;
